import React, { useEffect, useState } from "react";
import "../../vendors/styles/cricketAppStyles.css";


const UserManagement: React.FC = () => {


    return (
        <>
            <div className="main-container">
                <div className="container-fluid bg-white pt-3 pb-3 border-r-10">
                    <h4 className="text-center font-Poppins">Comming Soon</h4>
                </div>
            </div>
        </>
    );
};

export default UserManagement;
