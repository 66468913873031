import React, { useState } from "react";
import "../App.css";
import { If } from "../util/If";
import "./loader.css";
import { useTranslation } from "react-i18next";

export const NotFound: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);

  setTimeout(() => {
    setLoader(false);
    setErrorMessage(true);
  }, 2000);

  return (
    <div className="container pt-20">
      <div className="row">
        <div className="col-12">
          <div className="pt-20 text-center">
            <If condition={loader}>
              <span className="loader pt-20" />
            </If>
            <If condition={errorMessage}>
              <h4 className="pt-150 text-center text-default-color">{t('not_found')}</h4>
            </If>
            <div>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
