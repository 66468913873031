import React, { useEffect, useState } from "react";
import { AuthService } from "../../services/AuthService";
import { RequestState } from "../../RequestState";
import swal from "sweetalert";
import { Redirect } from "react-router";
import { RouteName } from "../../RouteName";
//import "../vendors/styles/core.css";
//import "../vendors/styles/style.css";
import "../vendors/styles/cricketAppStyles.css";
import loginImage from "../../components/vendors/images/login-page-img.png";
import logo from "../../components/vendors/images/xpGrowthLogo.png";
import loginImageLeft from "../../components/vendors/images/loginImageLeft.svg";
import loginImageRight from "../../components/vendors/images/loginImageRight.svg";
import loginCardImage from "../../components/vendors/images/loginCardImage.svg";
import userIconLogin from "../../components/vendors/images/userIconLogin.svg";
import userLoginPasswordIcon from "../../components/vendors/images/userLoginPasswordIcon.svg";
import { LoginData } from "../../models/LoginModel";
const Login: React.FC = () => {
  const token = AuthService.getToken();
  let loginData: LoginData = {
    email: "",
    password: "",
    loginMethod: "",
    remember: "",
  };

  const [remember, setRemember] = useState(false);
  const [userData, setUserData] = useState(loginData);
  const [error, setError] = useState<string>();
  const [loginRequestState, setLoginRequestState] = useState<RequestState>(
    RequestState.INITIAL
  );

  useEffect(() => {
    if (loginRequestState === RequestState.LOADING) {
      AuthService.userLogin(userData)
        .then(async (res) => {
          if (res.success) {
            console.log(res.data.token);
            AuthService.setToken(res.data.token);
            setLoginRequestState(RequestState.SUCCESS);
          } else {
            setError(res.error);
            setLoginRequestState(RequestState.FAILED);
          }
        })
        .catch((e) => {
          setError(e);
          setLoginRequestState(RequestState.FAILED);
        });
    } else if (loginRequestState === RequestState.FAILED) {
      swal({ title: error, icon: "error" });
    }
  }, [loginRequestState]);

  const handleChange = (event: any) => {
    console.log(event.target.checked);
  };

  const submitLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setUserData({
      email: (
        event.currentTarget.elements.namedItem("email") as HTMLInputElement
      ).value,
      password: (
        event.currentTarget.elements.namedItem("password") as HTMLInputElement
      ).value,
      loginMethod: "EMAIL",
      remember: remember ? "TRUE" : "FALSE",
    });
    setLoginRequestState(RequestState.LOADING);
    console.log("LOGIN REQUEST", loginRequestState);
  };

  if (token && loginRequestState === RequestState.SUCCESS) {
    return <Redirect to={RouteName.ADMIN_TOURNAMENT_LIST} />;
  }
  
  return (
    <>
      <div className="login-page">
        <div className="left-bg">
          <img src={loginImageLeft} alt="Left Background" />
        </div>
        <div className="right-bg">
          <img src={loginImageRight} alt="Right Background" />
        </div>

        <div className="container">
          <div className="login-body d-lg-flex text-center ">
            <div className="box-1 mt-md-0 ">
              <div className="mt-5 d-flex justify-content-center">
                <div className="login-form mt-5">
                  <form onSubmit={submitLogin}>
                    <p className="mb-1 h-1 text-center login-header">
                      Admin Login
                    </p>

                    <div className="textbox mt-5">
                      <img
                        src={userIconLogin}
                        alt="Input Icon"
                        className="input-icon"
                      />
                      <input
                        type="email"
                        placeholder="username / email"
                        name="email"
                        required
                      />
                    </div>
                    <div className="textbox mt-3">
                      <img
                        src={userLoginPasswordIcon}
                        alt="Input Icon"
                        className="input-icon"
                      />
                      <input
                        type="password"
                        placeholder="password"
                        name="password"
                        required
                      />
                    </div>
                    <div className="d-lg-flex justify-content-between mt-4 mb-3">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          id="lightBlueCheckbox"
                          className="custom-checkbox"
                          onChange={handleChange}
                        />
                        <span className="custom-checkbox-icon"></span>
                        Remember me
                      </label>
                      <p className="checkbox-label-para">
                        Forgot your password?
                      </p>
                    </div>
                    <div className="d-lg-flex justify-content-center mt-5 mb-5">
                      <button className="login-btn" type="submit">
                        Login<span className="fas fa-chevron-right ml-1"></span>
                      </button>
                    </div>
                    {/* <div className="d-flex justify-content-center mt-5">
                      <span className="login-end-text mt-2">
                        Dont't have an account ?
                      </span>
                      <a
                        href="http://localhost:3000/admin/dashboard"
                        className="ml-3 signup-btn mt-2"
                      >
                        Signup now
                      </a>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
            <div className=" box-2 d-flex flex-column h-100">
              <img src={loginCardImage} alt="Right Background" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
