import React, { useContext, useState,useEffect } from "react";
import UserContext from "../../context/UserContext";
import "../vendors/styles/core.css";
import "../vendors/styles/style.css";
import userIcon from "../vendors/images/user.png";
import { Upload } from "../../models/Upload";
import { MenuContext } from "../../context/MenuContext";
import "../vendors/styles/cricketAppStyles.css";
import PlayIcon from "../vendors/images/icon/fi_play.png"
import Header from "./header";
import {  NavLink,useHistory,useParams} from "react-router-dom";
import { AdminService } from "../../services/AdminService";
const NavBar: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const [isMenuOpen, setIsMenuOpen] = useContext(MenuContext);
  const [tournamentData, setTournamentData] = useState<any>(null);
  const { tournamentId }:any = useParams();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
 
  
      if (tournamentId) {
      
        AdminService.getTournamentDataByTournamentId(tournamentId).then((res) => {
          if (res.success) {
              setTournamentData(res.data)
           
          } else {
              console.log("error", res.error);
          }
      });
      }
    }, [])
  return (
    <div className="header">
      <div className="header-left">
        <div className="menu-icon ti ti-menu-alt mr-2" onClick={toggleMenu}></div>
      </div>
      <div className="header-right d-flex justify-content-between align-items-center flex-wrap">
          <div className="d-flex justify-content-start align-items-center header-container-left">
                                    <span className="header-name">{tournamentData?.name}</span>
                                        <span className="head-overs">Overs: {tournamentData?.oversPerMatch}</span>
          </div>
          <div className="d-flex justify-content-start align-items-center">
       
     
          <NavLink to="/admin/tournament-list" >
          <span className="head-tournament-list">Tournament List</span>
       
                        </NavLink>
                        <NavLink to="/admin/tournament-list" >
          <span className="head-tournament-list-none">Tournaments</span>
                        </NavLink>
        
         <div className="head-live d-flex justify-content-center align-items-center">
          <span>Live</span>
          <img src={PlayIcon} alt="" />
         </div>           
            </div>
       
    
      </div>
    </div>
  );
};

export default NavBar;
