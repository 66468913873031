import React, { useEffect, useState,useContext } from "react";
import {  NavLink,useHistory } from "react-router-dom";
import { AuthService } from "../../services/AuthService";
import { RequestState } from "../../RequestState";
import swal from "sweetalert";
import { Redirect } from "react-router";
import { RouteName } from "../../RouteName";
//import "../vendors/styles/core.css";
//import "../vendors/styles/style.css";
import "../vendors/styles/cricketAppStyles.css";
import loginImage from "../../components/vendors/images/login-page-img.png";
import logo from "../../components/vendors/images/xpGrowthLogo.png";
import loginImageLeft from "../../components/vendors/images/loginImageLeft.svg";
import loginImageRight from "../../components/vendors/images/loginImageRight.svg";
import createTournamentProfile from "../../components/vendors/images/createTournamentProfile.svg";
import userIconLogin from "../../components/vendors/images/userIconLogin.svg";
import userLoginPasswordIcon from "../../components/vendors/images/userLoginPasswordIcon.svg";
import { AdminService } from "../../services/AdminService";
import { TournamentData } from "../../models/Tournament";
import  TournamentContext  from "../../context/TournamentContext";

const CreateTournament: React.FC = () => {
  const [tournament, setTournament]= useContext(TournamentContext);
  const history = useHistory();
  const initialState : TournamentData = 
  { 
  name: "", 
  numberOfTeams: 0,
  leagueTotalMatches:0,
  oversPerMatch:0,
  semiFinalMatches:2,
  numberOfTeamMembers:11
};
//tournament matcher count ccount 
//over per per match
const [userData, setUserData] = useState(initialState);
const addTournament = ()=>{
  if (!userData.name) {
    swal({ icon: "error", title: "Please enter tournament name!" });
    return;
  }
  if (!userData.numberOfTeams) {
    swal({ icon: "error", title: "Please enter number of teams for tournament!" });
    return;
  }
  if (!userData.leagueTotalMatches) {
    swal({ icon: "error", title: "Please enter total matches for tournament!" });
    return;
  }
  if (!userData.oversPerMatch) {
    swal({ icon: "error", title: "Please enter number of overs for match!" });
    return;
  }

  AdminService.createTournament(userData).then((res) => {
    if (res.success) {
      swal({icon: "success", title: "New tournament is created!"}).then(function() {
        setUserData({...userData, name: '', oversPerMatch: 0});
        setTournament(res)
        history.push(`/admin/dashboard/${res?.tournamentId}`); 
      });   
    } else {
      swal({ icon: "error", title: res.error ? res.error : "Unable to create tournament!" });
    }
  });
}
  return (
    <>
      <div className="login-page">
        <div className="container">
          <div className="login-body d-lg-flex text-center ">
            <div className="box-1-create-tournament ">
              <div className="create-tournament-container border-r-10">
              <span className="mb-1 h-1 text-center tournament-creation-header mb-4">
                   Tournament Creation
                  </span>
                 
                  <p className="textbox-create-tournament-title text-left mt-3">Tournament Name</p>

                  <div className="textbox-create-tournament mb-3">
                 
                    <input type="text" placeholder=""
                    value={userData.name}
                    onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                     />
                  </div>
           
                  <p className="textbox-create-tournament-title text-left">Tournament Team Count</p>
                  <div className="textbox-create-tournament">
                    
                    <input type="number" placeholder=""
                    value={userData.numberOfTeams != 0 ? userData.numberOfTeams.toString() : ""}
                    onChange={(e) => setUserData({ ...userData, numberOfTeams : parseInt(e.target.value) })}
                    />
                  </div>
                  <p className="textbox-create-tournament-title text-left mt-3">Tournament Match Count</p>

                  <div className="textbox-create-tournament mb-3">

                  <input type="number" placeholder=""
                    value={userData.leagueTotalMatches != 0 ? userData.leagueTotalMatches.toString() : ""}
                    onChange={(e) => setUserData({ ...userData, leagueTotalMatches : parseInt(e.target.value) })}
                    />
                  </div>

                  <p className="textbox-create-tournament-title text-left">Overs Count</p>
                  <div className="textbox-create-tournament">
                    
                    <input type="number" placeholder=""
                    value={userData.oversPerMatch != 0 ? userData.oversPerMatch.toString() : ""}
                    onChange={(e) => setUserData({ ...userData, oversPerMatch : parseInt(e.target.value) })}
                    />
                  </div>
                 
                
                  <div className="d-lg-flex justify-content-center mt-5 mb-5">
                    <button className="create-tournament-btn" onClick={addTournament}>
                      Create Tournament<span className="fas fa-chevron-right ml-1"></span>
                    </button>
                  </div>
                
               
              </div>
            </div>
            <div className=" box-2-create-tournament d-flex flex-column h-100">
              <img src={createTournamentProfile} alt="Right Background" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTournament;
