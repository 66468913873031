import React, { useEffect, useState } from "react";
import { AuthService } from "../../../services/AuthService";
import { RequestState } from "../../../RequestState";
import swal from "sweetalert";
import { Redirect } from "react-router";
import { RouteName } from "../../../RouteName";
//import "../vendors/styles/core.css";
//import "../vendors/styles/style.css";
import "../../vendors/styles/cricketAppStyles.css";
import cricketBall from "../../../components/vendors/images/cricketBall.svg";
import cricketBat from "../../../components/vendors/images/cricketBat.svg";

import CurrentTeamData from "./CurrentTeamData";
import DashboardTopBar from "./DashboardTopBar";
import BallScoreCardComponent from "../../common/dashboardComponent/BallScoreCardComponent";
import RunsBowlingStatsComponent from "../../common/dashboardComponent/RunsBowlingStatsComponent";
import { PlayerService } from "../../../services/PlayerService";
import { environment } from "../../../environment/environment";
import { useParams } from "react-router-dom";
import { MatchService } from "../../../services/MatchService";
import BallScore from "../../common/dashboardComponent/BallScoreCardComponent";
import { useHistory } from "react-router-dom";
import { AdminService } from "../../../services/AdminService";

const Dashboard2: React.FC = () => {
  const token = AuthService.getToken();
  const initialState = { email: "", password: "" };
  const [userData, setUserData] = useState(initialState);
  const [error, setError] = useState<string>();
  const [loginRequestState, setLoginRequestState] = useState<RequestState>(
    RequestState.INITIAL
  );
  const history = useHistory();
  const { matchId } = useParams<{ matchId?: string }>();
  const [match, setMatch] = useState<any>(null);
  const [currentBatsman1, setCurrentBatsman1] = useState("");
  const [currentBatsman2, setCurrentBatsman2] = useState("");
  const [currentBowler, setCurrentBowler] = useState("");
  const [ballRuns, setBallRuns] = useState(0);
  const [currentBat, setCurrentBat] = useState("");
  const [runStatus, setRunStatus] = useState("RUNS");
  const [ballExtraRuns, setBallExtraRuns] = useState(0);
  const [extraRunType, setExtraRunType] = useState("");
  const [battingFirstTeam, setbattingFirstTeam] = useState("");
  const [ballingFirstTeam, setballingFirstTeam] = useState("");
  const splittedURL = window.location.pathname.split("/");
  const matchLiveId = splittedURL[splittedURL.length - 1];

  useEffect(() => {
    fetchTournament();
  }, [matchId]);

  const fetchTournament = async () => {
    try {
      const response = await MatchService.getMatchDetails(matchLiveId);

      if (response.success === true) {
        const matchData = response;
        console.log("res==>", response);
        setMatch(response.data);

        if (
          matchData.data.batSecondScoreboard &&
          matchData.data.batSecondScoreboard.currentlyBatting.length > 0
        ) {
          setCurrentBatsman1(
            matchData.data.batSecondScoreboard.currentlyBatting[0]
          );
        }

        if (
          matchData.data.batSecondScoreboard &&
          matchData.data.batSecondScoreboard.currentlyBatting.length > 1
        ) {
          setCurrentBatsman2(
            matchData.data.batSecondScoreboard.currentlyBatting[1]
          );
        }

        if (
          matchData.data.batSecondScoreboard &&
          matchData.data.batSecondScoreboard.currentlyBowling
        ) {
          setCurrentBowler(matchData.data.batSecondScoreboard.currentlyBowling);
        }

        console.log(matchData.data);

        setbattingFirstTeam(
          batFirstTeam(matchData.data.batFirst, matchData.data.teams).name
        );

        setballingFirstTeam(
          ballFirstTeam(matchData.data.batFirst, matchData.data.teams).name
        );
        // match?.batSecondScoreboard.currentlyBatting.map(
        //   (player: any, index: any) => (getPlayer(
        //     player,
        //     batSecondTeam(match?.batFirst, match?.teams)
        //       .players
        //   ).name))
      } else {
        console.error("Failed to fetch match data");
      }
    } catch (error) {
      console.error("Error while fetching match data:", error);
    }
  };

  const sample = () => {
    return "hiii";
  };

  const toggleNextScoreboard = () => {
    var extraRunsBallsCount = 0;

    if (match.batFirstScoreboard == null) {
      alert("Please score on first innings first!");
      return false;
    }

    match.batFirstScoreboard.playerBowls.filter(function (pb: any) {
      if (pb.status == "WIDE" || pb.status == "NO_BALL") {
        extraRunsBallsCount = extraRunsBallsCount + 1;
      }
    });

    window.open("/score-board2/" + match._id, "_blank");
  };

  // if (match === null) {
  //   return <div className="loading">Loading...</div>;
  // }

  // function getIdFromUrl(url: any) {
  //   const regex = /\/([^/]+)$/;
  //   const match = url.match(regex);
  //   if (match) {
  //     return match[1];
  //   } else {
  //     return null;
  //   }
  // }

  // const playToss = (teams: any) => {
  //   const url = window.;
  //   const matchId = getIdFromUrl(url);

  //   return (
  //     <div>
  //       {teams[0].name}
  //       &nbsp; VS &nbsp;
  //       {teams[1].name}
  //       <br />
  //       <br />
  //       <a href={`/play-toss/${matchId}`} className="btn btn-success">
  //         Play Toss
  //       </a>
  //     </div>
  //   );
  // };

  const batFirstTeam = (batFirst: any, teams: any) => {
    var batFirstTeam = teams.filter(function (t: any) {
      return t.id == batFirst;
    });

    return batFirstTeam[0];
  };

  const ballFirstTeam = (batFirst: any, teams: any) => {
    var ballFirstTeam = teams.filter(function (t: any) {
      return t.id != batFirst;
    });

    return ballFirstTeam[0];
  };

  console.log("ballingFirstTeam==>", ballingFirstTeam);
  const batSecondTeam = (batFirst: any, teams: any) => {
    var batSecondTeam = teams.filter(function (t: any) {
      return t.id !== batFirst;
    });

    console.log(batSecondTeam[0]);

    return batSecondTeam[0];
  };

  const getBatterRuns = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var playerRuns = 0;

    bowls.filter(function (b: any) {
      if (b.status == "RUNS" && b.batsmanId == playerId) {
        playerRuns = playerRuns + b.runs;
      }
    });

    return playerRuns;
  };
  const getBatFirstTotalRuns = (bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var totalRuns = 0;
    var totalWickets = 0;

    bowls.filter(function (b: any) {
      if (b.status != "WICKET") {
        totalRuns = totalRuns + b.runs;
      }

      if (b.status == "WICKET") {
        totalWickets = totalWickets + 1;
      }
    });

    return totalRuns + "/" + totalWickets;
  };

  const getTotalWides = (bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var totalWides = 0;

    bowls.filter(function (b: any) {
      if (b.status == "WIDE") {
        totalWides = totalWides + b.runs;
      }
    });

    return totalWides;
  };

  const getTotalNoBalls = (bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var totalNoBalls = 0;

    bowls.filter(function (b: any) {
      if (b.status == "NO_BALL") {
        totalNoBalls = totalNoBalls + b.runs;
      }
    });

    return totalNoBalls;
  };

  const getTotalLegByes = (bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var totalLegByes = 0;

    bowls.filter(function (b: any) {
      if (b.status == "LEG_BYE") {
        totalLegByes = totalLegByes + b.runs;
      }
    });

    return totalLegByes;
  };

  const ballSecondTeam = (batFirst: any, teams: any) => {
    var ballSecondTeam = teams?.filter(function (t: any) {
      return t.id == batFirst;
    });

    return ballSecondTeam ? ballSecondTeam[0] : null;
  };

  const getBowlerRuns = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var bowlerRuns = 0;

    bowls.filter(function (b: any) {
      if (b.status != "WICKET" && b.bowlerId == playerId) {
        bowlerRuns = bowlerRuns + b.runs;
      }
    });

    return bowlerRuns;
  };

  const getBowlerWickets = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var bowlerWickets = 0;

    bowls.filter(function (b: any) {
      if (b.status == "WICKET" && b.bowlerId == playerId) {
        bowlerWickets = bowlerWickets + 1;
      }
    });

    return bowlerWickets;
  };
  // const getBatterRuns = (playerId: any, bowls: any) => {
  //   if (bowls == null) {
  //     return "-";
  //   }

  //   var playerRuns = 0;

  //   bowls.filter(function (b: any) {
  //     if (b.status == "RUNS" && b.batsmanId == playerId) {
  //       playerRuns = playerRuns + b.runs;
  //     }
  //   });

  //   return playerRuns;
  // };

  // const getBowlerRuns = (playerId: any, bowls: any) => {
  //   if (bowls == null) {
  //     return "-";
  //   }

  //   var bowlerRuns = 0;

  //   bowls.filter(function (b: any) {
  //     if (b.status != "WICKET" && b.bowlerId == playerId) {
  //       bowlerRuns = bowlerRuns + b.runs;
  //     }
  //   });

  //   return bowlerRuns;
  // };

  // const getBowlerWickets = (playerId: any, bowls: any) => {
  //   if (bowls == null) {
  //     return "-";
  //   }

  //   var bowlerWickets = 0;

  //   bowls.filter(function (b: any) {
  //     if (b.status == "WICKET" && b.bowlerId == playerId) {
  //       bowlerWickets = bowlerWickets + 1;
  //     }
  //   });

  //   return bowlerWickets;
  // };

  // const getBatFirstTotalRuns = (bowls: any) => {
  //   if (bowls == null) {
  //     return "-";
  //   }

  //   var totalRuns = 0;
  //   var totalWickets = 0;

  //   bowls.filter(function (b: any) {
  //     if (b.status != "WICKET") {
  //       totalRuns = totalRuns + b.runs;
  //     }

  //     if (b.status == "WICKET") {
  //       totalWickets = totalWickets + 1;
  //     }
  //   });

  //   return totalRuns + "/" + totalWickets;
  // };

  // const getTotalWides = (bowls: any) => {
  //   if (bowls == null) {
  //     return "-";
  //   }

  //   var totalWides = 0;

  //   bowls.filter(function (b: any) {
  //     if (b.status == "WIDE") {
  //       totalWides = totalWides + b.runs;
  //     }
  //   });

  //   return totalWides;
  // };

  // const getTotalNoBalls = (bowls: any) => {
  //   if (bowls == null) {
  //     return "-";
  //   }

  //   var totalNoBalls = 0;

  //   bowls.filter(function (b: any) {
  //     if (b.status == "NO_BALL") {
  //       totalNoBalls = totalNoBalls + b.runs;
  //     }
  //   });

  //   return totalNoBalls;
  // };

  // const getTotalLegByes = (bowls: any) => {
  //   if (bowls == null) {
  //     return "-";
  //   }

  //   var totalLegByes = 0;

  //   bowls.filter(function (b: any) {
  //     if (b.status == "LEG_BYE") {
  //       totalLegByes = totalLegByes + b.runs;
  //     }
  //   });

  //   return totalLegByes;
  // };

  const getPlayer = (playerId: any, teamPlayers: any) => {
    if (!playerId) {
      return "";
    }

    var player = teamPlayers.filter(function (p: any) {
      return p._id == playerId;
    });

    return player[0];
  };

  // const getSortedBowling = (playerBowls: any) => {
  //   return playerBowls.sort((a: any, b: any) => {
  //     const dateA: any = new Date(a.createdAt);
  //     const dateB: any = new Date(b.createdAt);

  //     return dateB - dateA;
  //   });
  // };

  const updateCurrentPlayers = async () => {
    if (match.status == "NOT_STARTED") {
      alert("Please play toss first!");

      return false;
    }

    if (
      match.status == "BAT_FIRST_WIN" ||
      match.status == "BALL_FIRST_WIN" ||
      match.status == "MATCH_TIED"
    ) {
      alert("Maytch is already finalized!");

      return false;
    }

    if (!currentBatsman1) {
      alert("Please select first batsman!");

      return false;
    }

    if (!currentBowler) {
      alert("Please select current bowler!");

      return false;
    }

    if (currentBatsman1 == currentBatsman2) {
      alert("Same player selected as both batsman1 & batsman2");

      return false;
    }

    var currentPlayers = [];

    if (currentBatsman1) {
      currentPlayers.push(currentBatsman1);
    }

    if (currentBatsman2) {
      currentPlayers.push(currentBatsman2);
    }

    let battingSecondTeam = batSecondTeam(match?.batFirst, match?.teams);

    await MatchService.updateCurrentPlayers({
      matchId: match._id,
      battingTeamId: battingSecondTeam._id,
      currentlyBattingPlayers: currentPlayers,
      currentlyBowlingPlayer: currentBowler,
    })
      .then((response) => response)
      .then((data) => {
        fetchTournament();

        if (!data.success) {
          alert(data.message);
        } else {
          swal({ icon: "success", title: "Current Players Updated!" });
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const updateRuns = async () => {
    if (match.status == "NOT_STARTED") {
      alert("Please play toss first!");

      return false;
    }

    if (
      match.status == "BAT_FIRST_WIN" ||
      match.status == "BALL_FIRST_WIN" ||
      match.status == "MATCH_TIED"
    ) {
      alert("Maytch is already finalized!");

      return false;
    }

    if (!currentBat) {
      alert("Please select current batsman!");

      return false;
    }

    if (!currentBowler) {
      alert("Please select current bowler!");

      return false;
    }

    let battingSecondTeam = batSecondTeam(match?.batFirst, match?.teams);

    await MatchService.updateMatchScore({
      matchId: match._id,
      battingTeamId: battingSecondTeam._id,
      currentBattingPlayer: currentBat,
      currentBowlingPlayer: currentBowler,
      runs: ballRuns,
      status: runStatus,
    })
      .then((response) => response)
      .then((data) => {
        console.log(data);
        fetchTournament();
        setCurrentBat("");
        setBallRuns(0);
        setBallExtraRuns(0);
        setRunStatus("RUNS");

        if (!data.success) {
          alert(data.message);
        } else {
          swal({ icon: "success", title: "Match Score Updated!" });
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const updateExtraRuns = async () => {
    if (match.status == "NOT_STARTED") {
      alert("Please play toss first!");

      return false;
    }

    if (
      match.status == "BAT_FIRST_WIN" ||
      match.status == "BALL_FIRST_WIN" ||
      match.status == "MATCH_TIED"
    ) {
      alert("Maytch is already finalized!");

      return false;
    }

    if (!currentBowler) {
      alert("Please select current bowler!");

      return false;
    }

    if (!extraRunType) {
      alert("Please select extra run type!");

      return false;
    }

    if (ballExtraRuns == 0) {
      alert("Extra run count should not be 0!");

      return false;
    }

    let battingSecondTeam = batSecondTeam(match?.batFirst, match?.teams);

    await MatchService.updateExtraRuns({
      matchId: match._id,
      battingTeamId: battingSecondTeam._id,
      currentBowlingPlayer: currentBowler,
      runs: ballExtraRuns,
      status: extraRunType,
    })
      .then((response) => response)
      .then((data) => {
        fetchTournament();
        setCurrentBat("");
        setBallRuns(0);
        setBallExtraRuns(0);
        setExtraRunType("");
        setRunStatus("RUNS");

        if (!data.success) {
          alert(data.message);
        } else {
          swal({ icon: "success", title: "Extra Runs Updated!" });
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteLastBall = async () => {
    if (match.status == "NOT_STARTED") {
      alert("Please play toss first!");

      return false;
    }

    if (
      match.status == "BAT_FIRST_WIN" ||
      match.status == "BALL_FIRST_WIN" ||
      match.status == "MATCH_TIED"
    ) {
      alert("Maytch is already finalized!");

      return false;
    }

    let battingSecondTeam = batSecondTeam(match?.batFirst, match?.teams);

    if (window.confirm("Are you sure want to delete last ball?") == true) {
      await MatchService.deleteLastBall({
        matchId: match._id,
        battingTeamId: battingSecondTeam._id,
      })
        .then((response) => response)
        .then((data) => {
          fetchTournament();
          setCurrentBat("");
          setBallRuns(0);
          setBallExtraRuns(0);
          setExtraRunType("");
          setRunStatus("RUNS");

          if (!data.success) {
            alert(data.message);
          } else {
            swal({ icon: "success", title: "Last Ball Deleted Successfully!" });
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  // const getSingleBallClass = (ballStatus: any) => {
  //   if (ballStatus == "WIDE") {
  //     return "wide-ball";
  //   } else if (ballStatus == "NO_BALL") {
  //     return "no-ball";
  //   } else if (ballStatus == "LEG_BYE") {
  //     return "legbye-ball";
  //   } else if (ballStatus == "WICKET") {
  //     return "wicket-ball";
  //   } else {
  //     return "normal-ball";
  //   }
  // };
  console.log(
    "match?.batSecondScoreboard.currentlyBowling=>",
    match?.batSecondScoreboard.currentlyBowling,
    match?.batSecondScoreboard.currentlyBatting,
    match?.batFirst,
    match?.teams
  );

  // const [url1, setUrl1] = useState<string>(url);

  // const nextPage = () => {
  //   window.location.href = `${url1}`;
  // };
  const changeScreen = () => {
    history.push(`/admin/score-board/${matchLiveId}`);
  };
  const finalizedMatch = () => {
    const data = {
      matchId: matchId,
    };
    AdminService.finalizedMatch(data).then((res) => {
      if (res.success) {
        swal({ icon: "success", title: "Finalized!" }).then(function () {
          fetchTournament();
        });
      } else {
        swal({
          icon: "error",
          title: res?.error ? res.error : "Something went wrong",
        });
      }
    });
  }
  return (
    <>
      <div className="d-flex justify-content-center align-items-center dashboard-header">
        <span className="dashboard-title-txt">Match Title</span>
      </div>
      {/* <DashboardTopBar url={"score-board"} /> */}
      <div className="container-fluid mt-3">
        <div className="d-flex justify-content-center align-items-center outer-box">
          <div className="row inner-box d-flex justify-content-center align-items-center">
            <div className="col-xl-5  d-md-flex justify-content-between align-items-center ">
              <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                <span className="dropdown-text mb-2">Runs</span>
                <div className="dropdown">
                  <select
                    className="form-select shadow score-dropdown"
                    value={ballRuns}
                    onChange={(e) =>
                      setBallRuns((prev) => parseInt(e.target.value))
                    }
                  >
                    <option value="0">0 run</option>
                    <option value="1">1 run</option>
                    <option value="2">2 runs</option>
                    <option value="3">3 runs</option>
                    <option value="4">4 runs</option>
                    <option value="5">5 runs</option>
                    <option value="6">6 runs</option>
                    <option value="7">7 runs</option>
                    <option value="8">8 runs</option>
                    <option value="9">9 runs</option>
                    <option value="10">10 runs</option>
                  </select>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                <span className="dropdown-text mb-2">Runs / Wicket</span>
                <div className="dropdown">
                  <select
                    className="form-select shadow score-dropdown"
                    value={runStatus}
                    onChange={(e) => setRunStatus((prev) => e.target.value)}
                  >
                    <option value="RUNS">RUNS</option>
                    <option value="WICKET">WICKET</option>
                  </select>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                <span className="dropdown-text mb-2">Current Batter</span>
                <div className="dropdown">
                  <select
                    className="form-select shadow score-dropdown"
                    value={currentBat}
                    onChange={(e) => setCurrentBat((prev) => e.target.value)}
                  >
                    <option value="">No One</option>
                    {match?.batSecondScoreboard &&
                      match?.batSecondScoreboard.currentlyBatting.map(
                        (player: any, index: any) => (
                          <option value={player} key={index}>
                            {
                              getPlayer(
                                player,
                                batSecondTeam(match?.batFirst, match?.teams)
                                  .players
                              )?.name
                            }
                          </option>
                        )
                      )}
                  </select>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center mb-3">
                <div
                  onClick={updateRuns}
                  className="dashboard-update-btn d-flex justify-content-center align-items-center "
                >
                  Update runs
                </div>
              </div>
            </div>
            <div className="col-xl-1 d-flex justify-content-center">
              <div className="horizontal-divider"></div>
            </div>
            <div className="col-xl-5  d-md-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                <span className="dropdown-text mb-2">Extra Runs</span>
                <div className="dropdown">
                  <select
                    className="form-select shadow score-dropdown"
                    value={ballExtraRuns}
                    onChange={(e) =>
                      setBallExtraRuns((prev) => parseInt(e.target.value))
                    }
                  >
                    <option value="0">0 run</option>
                    <option value="1">1 run</option>
                    <option value="2">2 runs</option>
                    <option value="3">3 runs</option>
                    <option value="4">4 runs</option>
                    <option value="5">5 runs</option>
                    <option value="6">6 runs</option>
                    <option value="7">7 runs</option>
                    <option value="8">8 runs</option>
                    <option value="9">9 runs</option>
                    <option value="10">10 runs</option>
                  </select>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                <span className="dropdown-text mb-2">Extra Run Types</span>
                <div className="dropdown">
                  <select
                    className="form-select shadow score-dropdown"
                    value={extraRunType}
                    onChange={(e) => setExtraRunType((prev) => e.target.value)}
                  >
                    <option value="">NONE</option>
                    <option value="WIDE">WIDE</option>
                    <option value="NO_BALL">NO BALL</option>
                    <option value="LEG_BYE">LEG BYE</option>
                  </select>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                <div
                  onClick={updateExtraRuns}
                  className="dashboard-update-btn d-flex justify-content-center align-items-center mb-2"
                >
                  Update Extra
                </div>
                <div
                  onClick={deleteLastBall}
                  className="dashboard-update-btn-extra d-flex justify-content-center align-items-center "
                >
                  Delete last ball
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <button
                  className="dashboard-next-btn"
                  onClick={()=> changeScreen()}
                >
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <div className="d-flex flex-column" >
                      <span>Next Score</span>
                      <span>Board</span>
                    </div>
                    <div>
                      <span className="fas fa-chevron-right ml-1"></span>
                    </div>
                  </div>
                </button>
                {/* <button
                  className="dashboard-next-btn"
                  onClick={()=> finalizedMatch()}
                >
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <div className="d-flex flex-column" >
                      <span>Finalized match</span>
                    </div>
                    <div>
                      <span className="fas fa-chevron-right ml-1"></span>
                    </div>
                  </div>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-3 ">
        <div className="row">
          <div className="col-xl-4 mt-3">
            {/* <CurrentTeamData
              toggleNextScoreboard={toggleNextScoreboard}
              fetchTournament={fetchTournament}
              battingFirstTeam={battingFirstTeam}
              ballingFirstTeam={ballingFirstTeam}
            ></CurrentTeamData> */}
            <div className="container-fluid bg-white pt-3 pb-3 border-r-10">
              <div className="d-flex justify-content-center align-items-center dashboard-table-head ">
                <img src={cricketBall} alt="" className="mr-2" />{" "}
                <span className="dashboard-table-title-txt">
                  Balling Team : {battingFirstTeam}
                </span>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th scope="col">Bowler</th>
                      <th scope="col">Current Bowler</th>
                      <th scope="col">Runs/Wickets</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="dropdown">
                            <select
                              className="form-select shadow"
                              value={currentBowler}
                              onChange={(e) =>
                                setCurrentBowler((prev) => e.target.value)
                              }
                            >
                              <option value="">No One</option>
                              {match?.batFirstScoreboard &&
                                ballSecondTeam(
                                  match?.batFirst,
                                  match?.teams
                                ).players.map((player: any, index: any) => (
                                  <option value={player._id} key={index}>
                                    {player.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </th>
                      <td>
                        {match?.batSecondScoreboard &&
                          getPlayer(
                            match?.batSecondScoreboard.currentlyBowling,
                            ballSecondTeam(match?.batFirst, match?.teams)
                              .players
                          )?.name}
                      </td>
                      <td>
                        {match?.batSecondScoreboard &&
                          getBowlerRuns(
                            match?.batSecondScoreboard.currentlyBowling,
                            match?.batSecondScoreboard.playerBowls
                          )}{" "}
                        /
                        {match?.batSecondScoreboard &&
                          getBowlerWickets(
                            match?.batSecondScoreboard.currentlyBowling,
                            match?.batSecondScoreboard.playerBowls
                          )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center align-items-center dashboard-table-head ">
                <img src={cricketBat} alt="" className="mr-2" />{" "}
                <span className="dashboard-table-title-txt">
                  Batting Team : {ballingFirstTeam}
                </span>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th scope="col">Batter</th>
                      <th scope="col">Currently Playing</th>
                      <th scope="col">Runs</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="dropdown">
                            <select
                              className="form-select shadow"
                              value={currentBatsman1}
                              onChange={(e) =>
                                setCurrentBatsman1((prev) => e.target.value)
                              }
                            >
                              <option value="">No One</option>
                              {match?.batFirstScoreboard &&
                                batSecondTeam(
                                  match.batFirst,
                                  match.teams
                                ).players.map((player: any, index: any) => (
                                  <option value={player._id} key={index}>
                                    {player.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </th>
                      <td>
                        {/* {match?.batFirstScoreboard &&
                          getPlayer(
                            match?.batFirstScoreboard.currentlyBatting[0],
                            batFirstTeam(match?.batFirst, match?.teams).players
                          )?.name} */}
                        {match?.batSecondScoreboard &&
                          getPlayer(
                            match?.batSecondScoreboard.currentlyBatting[0],
                            batSecondTeam(match?.batFirst, match?.teams).players
                          )?.name}
                      </td>
                      <td>
                        {match?.batSecondScoreboard &&
                          getBatterRuns(
                            match?.batSecondScoreboard.currentlyBatting[0],
                            match?.batSecondScoreboard.playerBowls
                          )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="dropdown">
                            <select
                              className="form-select shadow"
                              value={currentBatsman2}
                              onChange={(e) =>
                                setCurrentBatsman2((prev) => e.target.value)
                              }
                            >
                              <option value="">No One</option>
                              {match?.batFirstScoreboard &&
                                batSecondTeam(
                                  match?.batFirst,
                                  match?.teams
                                ).players.map((player: any, index: any) => (
                                  <option value={player._id} key={index}>
                                    {player.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </th>
                      <td>
                        {match?.batSecondScoreboard &&
                          getPlayer(
                            match?.batSecondScoreboard.currentlyBatting[1],
                            batSecondTeam(match?.batFirst, match?.teams).players
                          )?.name}
                      </td>
                      <td>
                        {match?.batSecondScoreboard &&
                          getBatterRuns(
                            match?.batSecondScoreboard.currentlyBatting[1],
                            match?.batSecondScoreboard.playerBowls
                          )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-start align-items-center mb-3 ">
                <div
                  onClick={updateCurrentPlayers}
                  className="dashboard-update-btn-table d-flex justify-content-center align-items-center "
                >
                  Update
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            <BallScore
              toggleNextScoreboard={toggleNextScoreboard}
              fetchTournament={fetchTournament}
              battingFirstTeam={battingFirstTeam}
              ballingFirstTeam={ballingFirstTeam}
              match={match}
            />
            {/* <RunsBowlingStatsComponent
              toggleNextScoreboard={toggleNextScoreboard}
              fetchTournament={fetchTournament}
              battingFirstTeam={battingFirstTeam}
              ballingFirstTeam={ballingFirstTeam}
            ></RunsBowlingStatsComponent> */}
            <div className="container-fluid mt-3 mb-3">
              <div className="d-flex w-100 h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
                <div className="row w-100">
                  <div className="col-md-6">
                    <div className="container">
                      <div className="row border-top-10 bg-gray2 d-flex justify-content-center align-items-center">
                        <span className=" text-black line-h-24 f-20 mt-12 mb-12">
                          Scoreboard
                        </span>
                      </div>
                      {match?.batSecondScoreboard &&
                        batSecondTeam(match.batFirst, match.teams).players.map(
                          (player: any, index: any) => (
                            <>
                              <div
                                className={`row ${
                                  index % 2 !== 0 ? "bg-gray3" : ""
                                }`}
                                key={index}
                              >
                                <div className="between-content pl-1 pr-1 pt-2 pb-2">
                                  <div>
                                    <span className="fw-400 f-15 f-color-gray">
                                      {player.name}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="fw-400 f-15 f-color-gray">
                                      {getBatterRuns(
                                        player._id,
                                        match.batSecondScoreboard.playerBowls
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        )}
                      <div className="row   bg-blue2">
                        <div className="between-content pl-1 pr-1 pt-2 pb-2">
                          <div className="border-g-10">
                            <span className=" fw-500 f-15 f-color-black font-inter">
                              Total
                            </span>
                          </div>
                          <div>
                            <span className="fw-500 f-15 f-color-black font-inter">
                              {match?.batSecondScoreboard &&
                                getBatFirstTotalRuns(
                                  match?.batSecondScoreboard?.playerBowls
                                )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center align-items-center pl-1 pr-1 bg-gray4">
                        <div className="between-content pl-1 pr-1">
                          <div className="bg-gray4 h-1 pl-1 pr-1"></div>
                        </div>
                      </div>
                      <div className="row border-bottom-10 d-flex justify-content-center align-items-center  bg-blue2">
                        <div>
                          <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                            No Ball:
                          </span>
                          <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                            {match?.batSecondScoreboard &&
                              getTotalNoBalls(
                                match?.batSecondScoreboard.playerBowls
                              )}
                          </span>
                        </div>
                        <div>
                          <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                            Wide:
                          </span>
                          <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                            {match?.batSecondScoreboard &&
                              getTotalWides(
                                match?.batSecondScoreboard?.playerBowls
                              )}
                          </span>
                          <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                            Leg Bye:
                          </span>
                          <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                            {match?.batSecondScoreboard &&
                              getTotalLegByes(
                                match?.batSecondScoreboard.playerBowls
                              )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="container">
                      <div className="row border-top-10 bg-gray2 d-flex justify-content-center align-items-center">
                        <span className=" text-black line-h-24 f-20 mt-12 mb-12">
                          Balling Stat
                        </span>
                      </div>
                      {match?.batSecondScoreboard &&
                        ballSecondTeam(
                          match?.batFirst,
                          match?.teams
                        )?.players?.map((player: any, index: any) => (
                          <>
                            <div
                              className={`row ${
                                index % 2 !== 0 ? "bg-gray3" : ""
                              }`}
                              key={index}
                            >
                              <div className="between-content pl-1 pr-1 pt-2 pb-2">
                                <div>
                                  <span className="fw-400 f-15 f-color-gray">
                                    {player.name}
                                  </span>
                                </div>
                                <div>
                                  <span className="fw-400 f-15 f-color-gray">
                                    {match?.batSecondScoreboard &&
                                      getBowlerRuns(
                                        player._id,
                                        match?.batSecondScoreboard.playerBowls
                                      )}
                                    /
                                    {match?.batSecondScoreboard &&
                                      getBowlerWickets(
                                        player._id,
                                        match?.batSecondScoreboard.playerBowls
                                      )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard2;
