import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { RouteName } from "../../RouteName";
import "../vendors/styles/core.css";
import "../vendors/styles/style.css";
import "../vendors/styles/icon-font.css";
import logo from "../../components/vendors/images/xpWhiteLogo.svg";
import { useTranslation } from "react-i18next";
import { MenuContext } from "../../context/MenuContext";
import Logo from "../../components/vendors/images/icon/logo.png"
import matches from "../../components/vendors/images/matches.svg"
import pointstable from "../../components/vendors/images/pointstable.svg"
import dashboard from "../../components/vendors/images/dashboard.svg"
import userManagement from "../../components/vendors/images/fi_users.svg"

const AdminSideBar: React.FC = () => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useContext(MenuContext);
  const splittedURL = window.location.pathname.split("/");
  const tournamentId = splittedURL[splittedURL.length - 1];

  const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
  }
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };
  return (
    <div className={isMenuOpen ? 'left-side-bar open' : 'left-side-bar'}>
      <div className="brand-logo">
        <NavLink  to={`/admin/dashboard/${tournamentId}`}>
          <img src={Logo} className="light-logo" />
        </NavLink>

        <div className="close-sidebar" onClick={toggleMenu}>
          <i className="ion-close-round"></i>
        </div>
      </div>
      <div className="menu-block customscroll">
        <div className="sidebar-menu  d-flex justify-content-center align-items-center">
        
          <ul id="accordion-menu " className="sidebar-menu-container ">
          <li>
              <NavLink to={`/admin/dashboard/${tournamentId}`} onClick={toggleMenu} className={window.location.pathname == `/admin/dashboard/${tournamentId}` ? "dropdown-toggle selected-side no-arrow" : "dropdown-toggle no-arrow"}>
              <img src={dashboard} alt="" className="micon" />
                  <span className="mtext">Dashboard</span>
              </NavLink>
            </li>

            <li>
              <NavLink to={`/admin/matches/${tournamentId}`} onClick={toggleMenu} className={window.location.pathname == `/admin/matches/${tournamentId}` ? "dropdown-toggle selected-side no-arrow" : "dropdown-toggle no-arrow"}>
                  {/* <span className="micon dw dw-house-1"></span> */}
                
                 <img src={matches} alt="" className="micon" />
                  <span className="mtext">Matches</span>
              
              </NavLink>
            </li>

            <li>
              <NavLink to={`/admin/points-table/${tournamentId}`} onClick={toggleMenu} className={window.location.pathname == `/admin/points-table/${tournamentId}` ? "dropdown-toggle selected-side no-arrow" : "dropdown-toggle no-arrow"}>
              <img src={pointstable} alt="" className="micon" />
                  <span className="mtext">Points Table</span>
              </NavLink>
            </li>

            <li>
              <NavLink to={`/admin/user-management/${tournamentId}`} onClick={toggleMenu} className={window.location.pathname == `/admin/user-management/${tournamentId}` ? "dropdown-toggle selected-side no-arrow" : "dropdown-toggle no-arrow"}>
              <img src={userManagement} alt="" className="micon" />
                  <div className="d-flex flex-column">
                  <span className="mtext mt-1">User Management</span>
                  <span className="f-8 fw-400 border-green-20 pl-1 pr-1 f-color-black font-Poppins">Comming Soon</span>
                  </div>
              </NavLink>
            </li>
            <li>
              <div onClick={logout} className= "dropdown-toggle selected-side no-arrow">
              <img src={userManagement} alt="" className="micon" />
                  <span className="mtext mt-1">Logout</span>
                 
              </div>
            </li>
          </ul>
          
       
        
        </div>
      </div>
    </div>
  );
};

export default AdminSideBar;
