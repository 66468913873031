import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import { RouteName } from "../RouteName";
import Login from "./common/Login";
import Auth from "./common/Auth";
import NavBar from "./common/NavBar";
import VerifyRole from "./common/VerifyRole";
import { Role } from "../models/Role";
import ContentLayout from "./common/ContentLayout";
import SidePane from "./common/SidePane";
import Content from "./common/Content";
import { NotFound } from "./common/NotFound";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../translations/locales/en.json";
import fr from "../translations/locales/fr.json";
import LanguageDetector from "i18next-browser-languagedetector";
import { MenuContext } from "../context/MenuContext";
import TournamentContext from "../context/TournamentContext";
import Dashboard from "./Admin/Dashboard/Dashboard";
import LiveScore from "./Admin/live-score/index";
import CreateTournament from "./Admin/CreateTournament";
import TournamentList from "./Admin/TournamentList";
import Matches from "./Admin/Matches";
import PointsTable from "./Admin/PointsTable";
import MainDashboard from "./Admin/MainDashboard";
import AdminSideBar from "./Admin/AdminSideBar";
import Footer from "./common/Footer";
import UserManagement from "./Admin/UserManagement";
import Dashboard2 from "./Admin/Dashboard/Dashboard2";
const languages = ["en", "fr"];

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: { order: ["path", "navigator"] },
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
    },
    whitelist: languages,
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

const App: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>();
  const [tournament, setTournament] = useState<any>();
  return (
    <Router>
      <Switch>
        <Route path={["/", RouteName.LOGIN]} exact>
          <Login />
        </Route>

        <Auth>
          <TournamentContext.Provider value={[tournament, setTournament]}>
            <MenuContext.Provider value={[isMenuOpen, setIsMenuOpen]}>
              <div className="page-container">
                <div className="content-wrap">
                  <Router>
                    <Switch>
                      <Route path="/admin">
                        <SuperAdminRouter />
                      </Route>
                    </Switch>
                  </Router>
                </div>
                <Footer />
              </div>
            </MenuContext.Provider>
          </TournamentContext.Provider>
        </Auth>
      </Switch>
    </Router>
  );
};

const SuperAdminRouter: React.FC = () => {
  return (
    <ContentLayout>
      <Router>
        <Route
          path={[
            RouteName.ADMIN_MATCHES,
            RouteName.ADMIN_MAIN_DASHBOARD,
            RouteName.ADMIN_POINTS_TABLE,
            RouteName.ADMIN_USER_MANAGEMENT
          ]}
          exact
          render={() => (
            <>
              <NavBar /> 
              <SidePane>
                <AdminSideBar />
              </SidePane>
            </>
          )}
        />
        <Content>
          <Switch>
            <Route path={RouteName.ADMIN_MATCHES} exact>
              <Matches />
            </Route>
            <Route path={RouteName.ADMIN_POINTS_TABLE} exact>
              <PointsTable />
            </Route>
            <Route path={RouteName.ADMIN_MAIN_DASHBOARD} exact>
              <MainDashboard />
            </Route>
            <Route path={RouteName.ADMIN_DASHBOARD}>
              <Dashboard />
            </Route>
            <Route path={RouteName.ADMIN_DASHBOARD2}>
              <Dashboard2 />
            </Route>
            <Route path={RouteName.ADMIN_LIVE_SCORE}>
              <LiveScore />
            </Route>

            <Route path={RouteName.ADMIN_TOURNAMENT_LIST}>
              <TournamentList />
            </Route>
            <Route path={RouteName.ADMIN_CREATE_TOURNAMENT}>
              <CreateTournament />
            </Route>
            <Route path={RouteName.ADMIN_USER_MANAGEMENT}>
              <UserManagement/>
            </Route>
            <Route path="/">
              <NotFound />
            </Route>
          </Switch>
        </Content>
      </Router>
    </ContentLayout>
  );
};

export default App;
