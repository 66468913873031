import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../context/UserContext";
import "../vendors/styles/cricketAppStyles.css";
import { AdminService } from "../../services/AdminService";
import TournamentContext from "../../context/TournamentContext";
import { useParams, NavLink, useHistory } from 'react-router-dom';
import PlayIcon from "../vendors/images/icon/fi_play.png"
import CustomModal from "../common/Modal";
import RightArrow from "../vendors/images/icon/right-arrow.png"
import swal from "sweetalert";

const Matches: React.FC = () => {
    const history = useHistory();
    const [matchesData, setMatchesData] = useState<any[]>();
    const [user, setUser] = useContext(UserContext);
    const [tournament, setTournament] = useContext(TournamentContext);
    const { tournamentId }: any = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectTeams, setSelectTeams] = useState<any>([]);
    const [tosWinTeam, setTosWinTeam] = useState();
    const [batFirstTeam, setBatFirstTeam] = useState();
    const [updateMatchId, setUpdateMatchId] = useState();

    useEffect(() => {

        if (tournamentId) {

            AdminService.getMatchesByTournamentId(tournamentId).then((res) => {
                if (res.success) {
                    setMatchesData(res.data)
                } else {
                    console.log("error", res.error);
                }
            });
        } else {
            console.log('No tournament id not found');
        }
    }, []);


    const getResultStatus = (batFirst: any, teams: any, status: any, matchId: any) => {
        if (status == 'NOT_STARTED') {
            return "Not Started Yet!";
        } else if (status == 'ONGOING') {
            return (
                <span className="matches-team-win">Ongoing</span>
            )


        } else if (status == 'MATCH_TIED') {
            return "Match Tied!";
        } else if (status == 'BAT_FIRST_WIN') {
            var batFirstTeam = teams?.filter(function (t: any) {
                return t?.id == batFirst;
            });

            return (
                <span className="matches-team-win">{batFirstTeam[0]?.name + " wins!"}</span>
            )



        } else if (status == 'BALL_FIRST_WIN') {
            var ballFirstTeam = teams?.filter(function (t: any) {
                return t?.id != batFirst;
            });

            return (
                <span className="matches-team-win">{ballFirstTeam[0].name + " wins!"}</span>
            )



        } else {
            return "Not Started Yet!";
        }
    };

    const getTeamTotals = (matchId: any, batFirst: any, teams: any, status: any, batFirstScoreboard: any, batSecondScoreboard: any,) => {
        var batFirstTeam = teams?.filter(function (t: any) {
            return t?.id == batFirst;
        });

        var ballFirstTeam = teams?.filter(function (t: any) {
            return t?.id != batFirst;
        });


        if (status == 'NOT_STARTED') {
            if (teams?.length > 0) {
                return (

                    <span>-</span>

                )
            }
        } else {
            if (!batFirstScoreboard || !batSecondScoreboard) {
                return (
                    <span>-</span>
                )
            }

            var batFirstTotal = 0;
            var batFirstWickets = 0;

            batFirstScoreboard?.playerBowls?.filter(function (b: any) {
                if (b?.status != "WICKET") {
                    batFirstTotal = batFirstTotal + b?.runs;
                }

                if (b?.status == "WICKET") {
                    batFirstWickets = batFirstWickets + 1;
                }
            });

            var ballFirstTotal = 0;
            var ballFirstWickets = 0;

            batSecondScoreboard?.playerBowls?.filter(function (b: any) {
                if (b?.status != "WICKET") {
                    ballFirstTotal = ballFirstTotal + b?.runs;
                }

                if (b?.status == "WICKET") {
                    ballFirstWickets = ballFirstWickets + 1;
                }
            });
            return (
                <span className="matches-team-total">
                    {
                        batFirstTeam[0]?.name.split(" ")[0].charAt(0) + "" + (batFirstTeam[1]?.name.split(" ")[0].charAt(0) == undefined ? " : " : "." + batFirstTeam[1]?.name.split(" ")[0].charAt(0) + " : ") + "" + batFirstTotal + "/" + batFirstWickets
                    }

                    &nbsp;&nbsp; | &nbsp;&nbsp;

                    {
                        ballFirstTeam[0]?.name.split(" ")[0].charAt(0) + "" + (ballFirstTeam[1]?.name.split(" ")[0].charAt(0) == undefined ? " : " : "." + ballFirstTeam[1]?.name.split(" ")[0].charAt(0) + " : ") + "" + ballFirstTotal + "/" + ballFirstWickets
                    }
                </span>
            )




        }
    };
    const getTeamStatus = (matchId: any, batFirst: any, teams: any, status: any, batFirstScoreboard: any, batSecondScoreboard: any,) => {



        if (status == 'NOT_STARTED') {
            if (teams.length > 0) {
                return (

                    <span className="matches-team-live" onClick={() => startMatch(matchId, teams)}>Start Match</span>

                )
            }
        } else {
            if (!batFirstScoreboard || !batSecondScoreboard) {
                return (
                    <span className="matches-team-live" onClick={() => startMatch(matchId, teams)}>Start Match</span>

                )
            }


            return (

                <span className="matches-team-live" onClick={() => navigateScore(matchId)}>Live Scoreboard</span>

            )
        }
    };

    const navigateScore = (id: any) => {
        history.push(`/admin/live-score/${id}`);
    }
    const startMatch = (id: any, teams: any) => {
        // history.push(`/admin/score-board/${id}`);
        setUpdateMatchId(id)
        console.log("teams==>", teams)
        setSelectTeams(teams)
        handleOpenModal()
    }
    const updateStartMatch = () => {
        if (!tosWinTeam && !batFirstTeam) {
            
            swal({ icon: "error", title: "Please select tos win and bat first" });
        }else{
            const data = {
                matchId: updateMatchId,
                status: "ONGOING",
                tossWin: tosWinTeam,
                batFirst: batFirstTeam,
            };
            console.log("data==>",data)
            AdminService.updateMatchTossWinTeams(data).then((res) => {
                if (res.success) {
                    console.log("res==>", res)

                    swal({ icon: "success", title: "Start match!" }).then(function () {
                        history.push(`/admin/score-board/${updateMatchId}`)
                    });
                } else {
                    swal({ icon: "error", title: res?.error ? res.error : "Something went wrong" });
                }
            });
        }
    };
    const handleUpdateMatchTeam1 = (event: any) => {
        setTosWinTeam(event.target.value)
    };
    const handleUpdateMatchTeam2 = (event: any) => {
        setBatFirstTeam(event.target.value)
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>

            <div className="main-container">
                <div className="container-fluid bg-white pt-3 pb-3 border-r-10">

                    <div className="table-responsive">
                        <table className="table">
                            <thead className="table-head-matches ">
                                <tr className="text-center">
                                    <th>Match Id</th>
                                    <th>Type</th>
                                    <th>Teams</th>
                                    <th>Total</th>
                                    <th></th>
                                    <th></th>
                                    <th>Result</th>
                                </tr>
                            </thead>
                            <tbody className="table-body-matches">
                                {matchesData?.map((t: any, index: any) => (
                                    <tr className="team-row" key={index}>
                                        <td>Match {index + 1}</td>
                                        <td>{t.type}</td>
                                        <td className="team-sec">
                                            {
                                                <>
                                                    <div className="d-flex flex-wrap justify-content-center align-items-center matches-team-label">
                                                        {
                                                            t.teams.map((team: any, index1: any) => (



                                                                <span className="m-1" key={index1}>{team.name}</span>

                                                            ))
                                                        }
                                                    </div>
                                                </>
                                            }

                                            {t.teams.length == 0 &&
                                                <>-</>
                                            }
                                        </td>


                                        <td>
                                            {getTeamTotals(t._id, t.batFirst, t.teams, t.status, t.batFirstScoreboard, t.batSecondScoreboard)}
                                        </td>
                                        <td>
                                            {getTeamStatus(t._id, t.batFirst, t.teams, t.status, t.batFirstScoreboard, t.batSecondScoreboard)}
                                        </td>
                                        {
                                            t.status == "ONGOING" ?
                                                <NavLink to={`/admin/score-board/${t._id}`}  >
                                                    <td>
                                                        <div className="border-r-20 bg-rose2 center-content p-2">
                                                            <span className="f-16 f-color-white ml-2 pl-1">Live </span>
                                                            <img className="play-icon-size ml-2 mr-2" src={PlayIcon} />
                                                        </div>
                                                    </td>
                                                </NavLink>

                                                : <td></td>
                                        }


                                        <td>
                                            <div className="win-status">
                                                {getResultStatus(t.batFirst, t.teams, t.status, t._id)}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>

                </div>
            </div>
            <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
                {/* <h2 className="fw-600 f-17 f-color-black3 font-Poppins mb-4">Start Match </h2> */}
                <div>
                    <div className="d-flex justify-content-center align-item-center mb-2">
                        <span className="fw-400 f-15 f-color-black3 font-Poppins text-left">Toss Win</span>
                    </div>
                    <div className="mb-2 d-flex justify-content-center align-item-center">
                        <select className="select-dropdown fw-400 f-15 f-color-black3 font-Poppins" onChange={(e) => handleUpdateMatchTeam1(e)}>
                            <option className="fw-400 f-15 f-color-black3 font-Poppins" >Select Team</option>
                            {selectTeams?.map((team: any, index: number) => (
                                <option key={index} className="fw-400 f-15 f-color-black3 font-Poppins" value={team?._id}>{team?.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="d-flex justify-content-center align-item-center mb-2">
                        <span className="fw-400 f-15 f-color-black3 font-Poppins text-left">Bat first</span>
                    </div>
                    <div className="mb-2 d-flex justify-content-center align-item-center">
                    <select className="select-dropdown fw-400 f-15 f-color-black3 font-Poppins" onChange={(e) => handleUpdateMatchTeam2(e)}>
                            <option className="fw-400 f-15 f-color-black3 font-Poppins" >Select Team</option>
                            {selectTeams?.map((team: any, index: number) => (
                                <option key={index} className="fw-400 f-15 f-color-black3 font-Poppins" value={team?._id}>{team?.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="row pt-2 pl-3 pr-3 d-flex justify-content-center align-items-center">
                        <div className="arrow-btn2" onClick={() => updateStartMatch()}>
                            <span className="f-17 f-color-white fw-500 font-Poppins" >Start match</span>
                            <img className="arrow-icon-size ml-3" src={RightArrow} />
                        </div>
                    </div>
                </div>
            </CustomModal>
        </>
    );
};

export default Matches;
