import React, { useContext, useState } from "react";
import UserContext from "../../context/UserContext";
import "../vendors/styles/core.css";
import "../vendors/styles/style.css";
import userIcon from "../vendors/images/user.png";
import { Upload } from "../../models/Upload";
import { MenuContext } from "../../context/MenuContext";
import "../vendors/styles/cricketAppStyles.css";
import PlayIcon from "../vendors/images/icon/fi_play.png"

const Footer: React.FC = () => {
  const [user, setUser] = useContext(UserContext);
  const [isMenuOpen, setIsMenuOpen] = useContext(MenuContext);
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/login";
    setTimeout(function () {
      setUser(undefined);
    }, 100);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
 <div className="d-flex justify-content-end align-items-center">
  <div className="footer-badge d-flex justify-content-center align-items-center">
    <div className="d-flex justify-content-center align-items-center ">
      <span>Powered by @ efito solutions</span>
    </div>

  </div>
    {/* <div className="d-flex justify-center-end align-items-center footer-badge">
    <p className="">Powerd by @ efito solutions</p>
   </div> */}
 </div>
  );
};

export default Footer;
