import React, { useEffect, useState } from "react";
import "../../vendors/styles/cricketAppStyles.css";

interface Props {
  toggleNextScoreboard?: () => boolean | undefined;
  fetchTournament: () => Promise<void>;
  battingFirstTeam: string;
  ballingFirstTeam: string;
  match?: any;
  battingFirst?: boolean
}
interface BallingObject {
  ballNumber: number;
  batsmanId: string;
  boundary: boolean;
  bowlerId: string;
  createdAt: string;
  over: number;
  runs: number;
  status: string;
  _id: string;
}
const BallScore: React.FC<Props> = ({
  toggleNextScoreboard,
  fetchTournament,
  battingFirstTeam,
  ballingFirstTeam,
  match,
  battingFirst
}) => {
  const [boallingArr, setBoallingArr] = useState<BallingObject[]>([]);
  const [overByObject, setOverByObject] = useState<{ [over: number]: BallingObject[] }>();
  // console.log("match==>", match)
  // console.log("batFirstScoreboard==>", match?.batFirstScoreboard?.playerBowls)
  // setBoallingArr(boallingArr)
  useEffect(() => {
    
    if (battingFirst){
      const bollingArr1: BallingObject[] = match?.batFirstScoreboard?.playerBowls
      if (bollingArr1?.length > 0) {
        // console.log("bollingArr1==>", match?.batFirstScoreboard?.playerBowls)
        const sortedData = [...bollingArr1].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        setBoallingArr(bollingArr1);
        // console.log("sortedData==>", sortedData)
  
  
  
        const groupedByOver: { [over: number]: BallingObject[] } = {};
        bollingArr1.forEach((obj: BallingObject) => {
          const { over } = obj;
          if (!groupedByOver[over]) {
            groupedByOver[over] = [obj];
          } else {
            groupedByOver[over].push(obj);
          }
        });
  
        // console.log("groupedByOver==>", groupedByOver)
        setOverByObject(groupedByOver);
      }
    }else{
      const bollingArr1: BallingObject[] = match?.batSecondScoreboard?.playerBowls
      if (bollingArr1?.length > 0) {
        // console.log("bollingArr1==>", match?.batSecondScoreboard?.playerBowls)
        const sortedData = [...bollingArr1].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        setBoallingArr(bollingArr1);
        // console.log("sortedData==>", sortedData)
  
  
  
        const groupedByOver: { [over: number]: BallingObject[] } = {};
        bollingArr1.forEach((obj: BallingObject) => {
          const { over } = obj;
          if (!groupedByOver[over]) {
            groupedByOver[over] = [obj];
          } else {
            groupedByOver[over].push(obj);
          }
        });
  
        // console.log("groupedByOver==>", groupedByOver)
        setOverByObject(groupedByOver);
      }
    }
  }, [match]);
  // console.log("overByObject==>", overByObject)

  return (
    <>
      <div className="container-fluid mt-3 ">
        <div className="d-flex h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
          <div className="container">
            <div className="row border-top-10 bg-gray2 d-flex  align-items-center">
              <div className="bg-rose-gradient  w-p-33 polygon1 border-top-l-10">
                <p className="pl-5 text-white line-h-24 f-20 mt-12 mb-12 font-inter">
                  {battingFirstTeam} <span className="pl-4 pr-4">VS</span>
                  {ballingFirstTeam}
                </p>
              </div>
              {/* <div className="bg-rose polygon2 w-p-5">
                <p className="pl-5 text-white line-h-24 f-20 mt-12 mb-12">

                </p>
              </div> */}
            </div>
            <div className="container mt-4 border-g-10 border border-secondary ">
              <div className="ml-5 mr-5 row flex-nowrap overflow-auto " >
                
                {overByObject ? (
                  Object.keys(overByObject).map((overNumber) => {
                    const overObjects = overByObject[Number(overNumber)];

                    return (
                      <div key={overNumber} className="d-flex  ">
                        {overObjects.map((obj) => (
                          <div key={obj._id} className={`${obj?.status == "WICKET" ? "number-box-rose f-color-white" : obj?.status == "WIDE" ? "number-box-yellow f-color-black" : obj?.status == "NO_BALL" ? "number-box-blue f-color-white" : obj?.status == "LEG_BYE" ? "number-box-green f-color-white" : "f-color-black number-box"} mr-1 mt-3 mb-3 f-24 fw-700  font-Kameron`}>
                            {obj.runs}
                          </div>
                        ))}
                        <div className="number-box-white mr-1 mt-3 mb-3 f-16 fw-400 font-Kameron">
                          {overNumber}{overNumber == "1" ? "st" : overNumber == "2" ? "nd" : overNumber == "3" ? "rd" : "th"}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>Yet to bat...</div>
                )}
              </div>
            </div>
            <div className="row mt-4 d-flex justify-content-center align-items-center flex-wrap">
              <div className="d-flex align-items-center mr-3">
                <div className="dot bg-rose"></div>
                <span className="ml-2 f-12 fw-400 f-color-gray font-inter">
                  Wicket
                </span>
              </div>
              <div className="d-flex align-items-center mr-3">
                <div className="dot bg-yellow"></div>
                <span className="ml-2 f-12 fw-400 f-color-gray font-inter">
                  Wide
                </span>
              </div>
              <div className="d-flex align-items-center mr-3">
                <div className="dot bg-blue"></div>
                <span className="ml-2 f-12 fw-400 f-color-gray font-inter">
                  No Ball
                </span>
              </div>
              <div className="d-flex align-items-center mr-3">
                <div className="dot bg-green"></div>
                <span className="ml-2 f-12 fw-400 f-color-gray font-inter">
                  Leg Bye
                </span>
              </div>
              <div className="d-flex align-items-center mr-3">
                <div className="dot bg-gray"></div>
                <span className="ml-2 f-12 fw-400 f-color-gray font-inter">
                  Normal
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BallScore;
