import React, { useEffect, useState, useContext } from "react";
import "../vendors/styles/cricketAppStyles.css";
import { AdminService } from "../../services/AdminService";
import TournamentContext from "../../context/TournamentContext";
import { useParams } from 'react-router-dom';
const PointsTable: React.FC = () => {
  const [tournament, setTournament] = useContext(TournamentContext);
  const [allMatches, setAllMatches] = useState<any[]>();
  const [teams, setTeams] = useState<any[]>();
  const { tournamentId }:any = useParams();
  useEffect(() => {
    if (tournamentId) {
      AdminService.getAllMatchesData(tournamentId).then((res) => {
        if (res.success) {
          setAllMatches(res.data);
          allTeamData();
        } else {
          console.log("error", res.error);
        }
      });
    } else {
      console.log("No tournamentId found");
    }
  }, [tournamentId]);

  const allTeamData = () => {
    AdminService.getAllTeamsByTournamentId(tournamentId).then((res) => {
      if (res.success) {
        setTeams(res.data);
        getAllPlayers();
      } else {
        console.log("error", res.error);
      }
    });
  };
  const getTeamStatistics = () => {
    var sortedTeams: any = [];

    if (teams) {
      teams &&
        teams.filter( (team) => {
          var playedMatches = 0;
          var wonMatches = 0;
          var lostMatches = 0;
          var tiedMatches = 0;
          var points = 0;

          allMatches?.filter( (match) => {
            if (match.type === "REGULAR") {
              var teamFound = match.teams.filter( (t:any) => {
                return t._id === team._id;
              });

              if (teamFound.length > 0) {
                if (match.status === "BAT_FIRST_WIN") {
                  playedMatches = playedMatches + 1;

                  if (match.batFirst === team._id) {
                    wonMatches = wonMatches + 1;
                    points = points + 2;
                  } else {
                    lostMatches = lostMatches + 1;
                  }
                } else if (match.status === "BALL_FIRST_WIN") {
                  playedMatches = playedMatches + 1;

                  if (match.batFirst === team._id) {
                    lostMatches = lostMatches + 1;
                  } else {
                    wonMatches = wonMatches + 1;
                    points = points + 2;
                  }
                } else if (match.status === "MATCH_TIED") {
                  playedMatches = playedMatches + 1;

                  tiedMatches = tiedMatches + 1;
                  points = points + 1;
                }
              }
            }
          });

          sortedTeams.push({
            name: team.name,
            playedMatches: playedMatches,
            wonMatches: wonMatches,
            lostMatches: lostMatches,
            tiedMatches: tiedMatches,
            points: points,
          });
        });

      sortedTeams.sort(function (a: any, b: any) {
        var nameA = a.points,
          nameB = b.points;

        if (nameA < nameB) return 1;
        if (nameA > nameB) return -1;
        return 0;
      });

      return sortedTeams;
    } else {
      return [];
    }
  };

  const getAllPlayers = () => {
    var allPlayers: any = [];

    teams &&
      teams.filter( (team) => {
        var teamMatches = allMatches?.filter( (m:any)=> {
          var teamFound = m.teams.filter( (t: any) =>{
            return t._id === team._id;
          });

          if (teamFound.length > 0) {
            return true;
          }
        });

        team.players.filter( (player: any)=> {
          var playerTotalRuns = 0;
          var playerTotalWicktes = 0;
          var playerTotalBoundaries = 0;
          teamMatches?.filter( (m: any) =>{
            if (m.batFirst === team._id) {
              m.batFirstScoreboard &&
                m.batFirstScoreboard.playerBowls.filter( (pb: any) =>{
                 
                  if (pb.status === "RUNS" && pb.batsmanId === player._id) {
                    playerTotalRuns = playerTotalRuns + pb.runs;
                    console.log("runs", pb.runs);

                    if (pb.runs === 4 || pb.runs === 6) {
                      playerTotalBoundaries = playerTotalBoundaries + 1;
                    }
                  }

                  if (pb.status === "WICKET" && pb.bowlerId === player._id) {
                    playerTotalWicktes = playerTotalWicktes + 1;
                  }
                });
            } else {
              m.batSecondScoreboard &&
                m.batSecondScoreboard.playerBowls.filter( (pb: any) =>{
                  
                  if (pb.status === "RUNS" && pb.batsmanId === player._id) {
                    playerTotalRuns = playerTotalRuns + pb.runs;

                    if (pb.runs === 4 || pb.runs === 6) {
                      playerTotalBoundaries = playerTotalBoundaries + 1;
                    }
                  }

                  if (pb.status === "WICKET" && pb.bowlerId === player._id) {
                    playerTotalWicktes = playerTotalWicktes + 1;
                  }
                });
            }
          });

          allPlayers?.push({
            name: player.name,
            playerTotalRuns: playerTotalRuns,
            playerTotalWicktes: playerTotalWicktes,
            playerTotalBoundaries: playerTotalBoundaries,
          });
        });
      });

    return allPlayers;
  };
  const getAllPlayersWickets = () => {
    var allPlayers: any = [];

    teams &&
      teams.filter( (team)=> {
        var teamMatches = allMatches?.filter( (m:any)=> {
          var teamFound = m.teams.filter( (t: any)=> {
            return t._id === team._id;
          });

          if (teamFound.length > 0) {
            return true;
          }
        });

        team.players.filter( (player: any)=> {
          var playerTotalRuns = 0;
          var playerTotalWicktes = 0;
          var playerTotalBoundaries = 0;

          teamMatches?.filter( (m: any)=> {
            if (m.batFirst === team._id) {
              m.batSecondScoreboard &&
                m.batSecondScoreboard.playerBowls.filter( (pb: any)=> {
                  

                  if (pb.status === "RUNS" && pb.batsmanId === player._id) {
                    playerTotalRuns = playerTotalRuns + pb.runs;

                    if (pb.runs === 4 || pb.runs === 6) {
                      playerTotalBoundaries = playerTotalBoundaries + 1;
                    }
                  }

                  if (pb.status === "WICKET" && pb.bowlerId === player._id) {
                    playerTotalWicktes = playerTotalWicktes + 1;
                  }
                });
            } else {
              m.batFirstScoreboard &&
                m.batFirstScoreboard.playerBowls.filter( (pb: any) =>{
                 
                  if (pb.status === "RUNS" && pb.batsmanId === player._id) {
                    playerTotalRuns = playerTotalRuns + pb.runs;

                    if (pb.runs === 4 || pb.runs === 6) {
                      playerTotalBoundaries = playerTotalBoundaries + 1;
                    }
                  }

                  if (pb.status === "WICKET" && pb.bowlerId === player._id) {
                    playerTotalWicktes = playerTotalWicktes + 1;
                  }
                });
            }
          });

          allPlayers.push({
            name: player.name,
            playerTotalRuns: playerTotalRuns,
            playerTotalWicktes: playerTotalWicktes,
            playerTotalBoundaries: playerTotalBoundaries,
          });
        });
      });

    return allPlayers;
  };
  const getMostRuns = () => {
    var allPlayers = getAllPlayers();
    console.log("all players data", allPlayers);
    allPlayers.sort(function (a: any, b: any) {
      var nameA = a.playerTotalRuns,
        nameB = b.playerTotalRuns;

      if (nameA < nameB) return 1;
      if (nameA > nameB) return -1;
      return 0;
    });

    return allPlayers;
  };

  const getMostWickets = () => {
    var allPlayers: any = getAllPlayersWickets();

    allPlayers.sort(function (a: any, b: any) {
      var nameA = a.playerTotalWicktes,
        nameB = b.playerTotalWicktes;

      if (nameA < nameB) return 1;
      if (nameA > nameB) return -1;
      return 0;
    });

    return allPlayers;
  };

  const getMostBoundaries = () => {
    var allPlayers: any = getAllPlayers();

    allPlayers.sort(function (a: any, b: any) {
      var nameA = a.playerTotalBoundaries,
        nameB = b.playerTotalBoundaries;

      if (nameA < nameB) return 1;
      if (nameA > nameB) return -1;
      return 0;
    });

    return allPlayers;
  };

  return (
    <>
      <div className="main-container">
        <div className="container-fluid bg-white pt-3 pb-3 border-r-10">
          <div className="table-responsive">
            <table className="table">
              <thead className="table-head-matches ">
                <tr className="text-center">
                  <th scope="col">Team</th>
                  <th scope="col">Played</th>
                  <th scope="col">Won</th>
                  <th scope="col">Lost</th>
                  <th scope="col">Tied</th>
                  <th scope="col">Points</th>
                </tr>
              </thead>
              <tbody className="table-body-matches ">
                {getTeamStatistics()?.map((team: any, index: any) => (
                  <tr key={index} className="text-center">
                    <td>{team.name}</td>
                    <td>{team.playedMatches}</td>
                    <td>{team.wonMatches}</td>
                    <td>{team.lostMatches}</td>
                    <td>{team.tiedMatches}</td>
                    <td>{team.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="table-responsive">
                <table className="table points-table">
                  <thead className="table-head-matches ">
                    <tr className="text-center">
                      <th scope="col">Most Runs</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {getMostRuns()?.map((player: any, index: any) => (
                      <tr
                        className="d-flex justify-content-between flex-wrap"
                        key={index}
                      >
                        <td>{player.name}</td>
                        <td>{player.playerTotalRuns}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="table-responsive">
                <table className="table points-table">
                  <thead className="table-head-matches ">
                    <tr className="text-center">
                      <th scope="col">Most Wickets</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {getMostWickets()?.map((player: any, index: any) => (
                      <tr
                        className="d-flex justify-content-between flex-wrap"
                        key={index}
                      >
                        <td>{player.name}</td>
                        <td>{player.playerTotalWicktes}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="table-responsive">
                <table className="table points-table">
                  <thead className="table-head-matches ">
                    <tr className="text-center">
                      <th scope="col">Most Boundaries</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {getMostBoundaries()?.map((player: any, index: any) => (
                      <tr
                        className="d-flex justify-content-between flex-wrap"
                        key={index}
                      >
                        <td>{player.name}</td>
                        <td>{player.playerTotalBoundaries}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PointsTable;
