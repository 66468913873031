export enum RouteName {
  ROOT = "/",
  LOGIN = "/login",

  ADMIN_DASHBOARD = "/admin/score-board/:matchId?",
  ADMIN_DASHBOARD2 = "/admin/score-board2/:matchId?",
  ADMIN_CREATE_TOURNAMENT = "/admin/create-tournament",
  ADMIN_TOURNAMENT_LIST = "/admin/tournament-list",
  ADMIN_MATCHES="/admin/matches/:tournamentId?",
  ADMIN_POINTS_TABLE="/admin/points-table/:tournamentId?",
  ADMIN_LIVE_SCORE = "/admin/live-score/:matchId",
  ADMIN_MAIN_DASHBOARD = "/admin/dashboard/:tournamentId?",
  ADMIN_USER_MANAGEMENT = "/admin/user-management/:tournamentId?",

  SUPER_ADMIN_SETTINGS = "/super-admin/settings",
  SUPER_ADMIN_COMPANIES = "/super-admin/companies",
  SUPER_ADMIN_ADD_COMPANY = "/super-admin/add-company",
  SUPER_ADMIN_EDIT_COMPANY = "/super-admin/edit-company/:companyId",
  SUPER_ADMIN_COMPANY_DETAILS = "/super-admin/company-info/",
  SUPER_ADMIN_ADD_COMPANY_ADMIN = "/super-admin/add-company-admin/",
  SUPER_ADMIN_EDIT_COMPANY_ADMIN = "/super-admin/edit-company-admin/",

  COMPANY_ADMIN_SETTINGS = "/admin/settings",
  COMPANY_ADMIN_COMPANY_PROFILE = "/admin/company-profile",
  COMPANY_ADMIN_EMPLOYEES = "/admin/employees",
  COMPANY_ADMIN_ADD_EMPLOYEE = "/admin/add-employee",
  COMPANY_ADMIN_EDIT_EMPLOYEE = "/admin/edit-employee/:employeeId",
  COMPANY_ADMIN_EMPLOYEE_TEAMS = "/admin/employee-teams/:employeeId",
  COMPANY_ADMIN_EMPLOYEE_APPOINTMENTS = "/admin/employee-events/:employeeId",
  COMPANY_ADMIN_SECURE_SAFE_ADD_FILE = "/admin/secure-safe/add-file/:employeeId",
  COMPANY_ADMIN_VIRTUAL_ECOSYSTEM_ADD_FILES = "/admin/virtual-ecosystem/add-files",
  COMPANY_ADMIN_VIRTUAL_ECOSYSTEM = "/admin/virtual-ecosystem",
  COMPANY_ADMIN_PROFILE = "/admin/profile",
  COMPANY_ADMIN_VIEW_SECURESAFE = "/admin/secure-safe-files/:employeeId",
  COMPANY_ADMIN_DEPARTMENTS = "/admin/departments",
  COMPANY_ADMIN_ADD_DEPARTMENT = "/admin/add-department",
  COMPANY_ADMIN_EDIT_DEPARTMENT = "/admin/edit-department/:departmentId",
  COMPANY_ADMIN_ESIGN = "/admin/esign",

  EMPLOYEE_HOME = "/welcome",
  EMPLOYEE_SETTINGS = "/settings",
  EMPLOYEE_SECURE_SAFE = "/my-security",
  EMPLOYEE_VIRTUAL_ECOSYSTEM = "/my-virtual-internal-ecosystem",
  EMPLOYEE_MY_TEAM = "/my-teams",
  EMPLOYEE_MY_ROOMS = "/my-rooms",
  EMPLOYEE_CREATE_NEW_ROOM = "/create-new-room",
  EMPLOYEE_EDIT_ROOM = "/create-new-room/:roomId",
  EMPLOYEE_APPOINTMENTS = "/my-onboarding",
  EMPLOYEE_APPOINTMENT_BY_ID = "/appointment/:appointmentId",
  EMPLOYEE_APPOINTMENTS_CALENDAR = "/my-onboarding-calendar",
  EMPLOYEE_CREATE_APPOINTMENT = "/create-appointment/:chatroomId?",
  EMPLOYEE_EDIT_APPOINTMENT = "/edit-appointment/:eventId",
  EMPLOYEE_CHAT = "/chat/:roomId",
  EMPLOYEE_ESIGN = "/esign",
}
