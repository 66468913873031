import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import "../../vendors/styles/cricketAppStyles.css";
import ScoreCard from "../../common/dashboardComponent/ScoreCardComponent";
import BallScore from "../../common/dashboardComponent/BallScoreCardComponent";
import GreenDot from "../../../../src/components/vendors/images/icon/green-dot.png";
import BatIcon from "../../../../src/components/vendors/images/icon/bat-icon.png";
import { MatchService } from "../../../services/MatchService";
import { set } from "date-fns";
import { on } from "events";
import BallScoreCardComponent from "../../common/dashboardComponent/BallScoreCardComponent";
import { is } from "date-fns/locale";
import { useParams } from "react-router-dom";

const LiveScore: React.FC = (matchId: any) => {
  const [tournamentTitle, setTournamentTitle] = useState<any>(null);
  const [matchData, setMatchData] = useState<any>(null);
  // const [currentlyBowlingPlayerObject, setCurrentlyBowlingPlayerObject] =
  //   useState<any>(null);
  const [currentlyBowlingteamObject, setCurrentlyBowlingteamObject] =
    useState<any>(null);
  const [onStrikeBatsmanId, setOnStrikeBatsmanId] = useState<any>(null);
  const [currentlyBattingPlayersObject, setCurrentlyBattingPlayersObject] =
    useState<any>(null);
  const [allBattersDetails, setAllBattersDetails] = useState<any[]>([]);
  const [totalRuns, SetTotalRuns] = useState<any>(null);
  const [extrasObject, setExtrasCountsObject] = useState({
    noBalls: 0,
    wickets: 0,
    wides: 0,
    legByes: 0,
  });
  const [allBowlers, setAllBowlers] = useState<any[]>([]);
  const [currentBowlerStats, setCurrentBowlerStats] = useState<any>(null);
  const [currentlyBattingTeamDetails, setCurrentlyBattingTeamDetails] =
    useState<any>(null);

  const [isBattingFirstDoneBatting, setIsBattingFirstDoneBatting] =
    useState<boolean>(false);
  const [isCurrentlyBattingFirstInning, setCurrentlyBattingFirstInning] =
    useState<boolean>(false);

  const [oversDataObject, setOversDataObject] = useState<any>(null);
  const [isMounted, setIsMounted] = useState(true);

  const splittedURL = window.location.pathname.split("/");
  const match_id = splittedURL[splittedURL.length - 1];
  const [pageNumber, setPageNumber] = useState(1);
  const [currentBowlerData, setCurrentBowlerData] = useState<any>(null);
  const [firstInningBatsmenData, setFirstInningBatsmenData] =
    useState<any>(null);
  const [firstInningBowlerData, setFirstInningBowlerData] = useState<any>(null);

  const [firstInningsSummary, setFirstInningsSummary] = useState<any>(null);

  useEffect(() => {
    fetchMatch();
  }, [pageNumber]);

  const fetchMatch = async () => {
    MatchService.getMatchDetails(match_id)
      .then((res) => {
        if (
          res.data.status === "BAT_FIRST_WIN" ||
          res.data.status === "BALL_FIRST_WIN"
        ) {
          setPageNumber(3);
        }
        const response = res.data;
        setMatchData(response);
        // -------------------------------------------------------------------------------------------get Currently Batting and Bowling ScoreBoards-----------------------------------------------------

        let scoreboardWithCurrentlyBowling;
        let isBattingFirstDoneBatting = false;

        let currentlyBattingFirstInning = true;

        const isCurrentlyBowlingExistsInSecondScoreBoard =
          response.batSecondScoreboard.hasOwnProperty("currentlyBowling");
        const isCurrentlyBowlingExistsInFirstScoreBoard =
          response.batFirstScoreboard.hasOwnProperty("currentlyBowling");

        if (
          isCurrentlyBowlingExistsInFirstScoreBoard &&
          !isCurrentlyBowlingExistsInSecondScoreBoard
        ) {
          console.log("First batting team is currently BATTING");
          scoreboardWithCurrentlyBowling = response.batFirstScoreboard;
          isBattingFirstDoneBatting = false;
          currentlyBattingFirstInning = true;
        } else if (
          isCurrentlyBowlingExistsInFirstScoreBoard &&
          isCurrentlyBowlingExistsInSecondScoreBoard
        ) {
          console.log("Second batting team is currently BATTING");
          scoreboardWithCurrentlyBowling = response.batSecondScoreboard;
          isBattingFirstDoneBatting = true;
          currentlyBattingFirstInning = false;
        } else {
          console.log(
            "No team is currently bowling, either the match is not started or innings changed"
          );
        }

        if (pageNumber === 1) {
          isBattingFirstDoneBatting = true;
        } else if (pageNumber === 2) {
          isBattingFirstDoneBatting = false;
        }

        setIsBattingFirstDoneBatting(isBattingFirstDoneBatting);
        setCurrentlyBattingFirstInning(currentlyBattingFirstInning);
        // -------------------------------------------------------------------------------------------get Currently Bowling Team and Player-------------------------------------------------------------

        const currentlyBowlingPlayerId =
          scoreboardWithCurrentlyBowling.currentlyBowling;

        const findBowlerById = (playerId: any) => {
          const allPlayers = response.teams.flatMap(
            (team: { players: any }) => team.players
          );
          return allPlayers.find(
            (player: { _id: any }) => player._id === playerId
          );
        };

        const findBowlingTeamById = (teamId: any) => {
          return response.teams.find(
            (team: { _id: any }) => team._id === teamId
          );
        };

        const currentBowlingTeamDetails = findBowlingTeamById(
          findBowlerById(currentlyBowlingPlayerId).teamId
        );

        setCurrentlyBowlingteamObject(currentBowlingTeamDetails);

        const findBowlerByIdInTeam = (
          team: { players: any[] },
          playerId: any
        ) => {
          return team.players.find(
            (player: { _id: any }) => player._id === playerId
          );
        };

        const playerInCurrentBowlingTeam = findBowlerByIdInTeam(
          currentBowlingTeamDetails,
          currentlyBowlingPlayerId
        );

        setCurrentBowlerData(playerInCurrentBowlingTeam);

        const bowlerBowled = scoreboardWithCurrentlyBowling.playerBowls;

        let runsGiven = 0;
        let wicketsTaken = 0;

        for (const ball of bowlerBowled) {
          if (ball.bowlerId === currentlyBowlingPlayerId) {
            runsGiven += ball.runs;
            if (ball.status === "WICKET") {
              wicketsTaken++;
            }
          }
        }

        const currentBowlerStats = {
          bowlerId: currentlyBowlingPlayerId,
          runsGiven: runsGiven,
          wicketsTaken: wicketsTaken,
        };

        setCurrentBowlerStats(currentBowlerStats);

        // -------------------------------------------------------------------------------------------get Currently Batting Team details----------------------------------------------------------------
        const playerBowls = scoreboardWithCurrentlyBowling.playerBowls;
        const currentlyBattingIds =
          scoreboardWithCurrentlyBowling.currentlyBatting;

        const findPlayerById = (playerId: any) => {
          const allPlayers = response.teams.flatMap(
            (team: { players: any }) => team.players
          );
          return allPlayers.find(
            (player: { _id: any }) => player._id === playerId
          );
        };

        const findTeamById = (teamId: any) => {
          return response.teams.find(
            (team: { _id: any }) => team._id === teamId
          );
        };

        const currentBattingTeamDetails = findTeamById(
          findPlayerById(currentlyBattingIds[0]).teamId
        );

        setCurrentlyBattingTeamDetails(currentBattingTeamDetails);

        const currentlyBattingPlayersObj = currentlyBattingIds?.map(
          (id: any) => {
            const player = findPlayerById(id);
            const team = findTeamById(player.teamId);

            // Find the player's runs from playerBowls
            const playerRuns = playerBowls
              .filter(
                (bowl: { batsmanId: any; runs: number }) =>
                  bowl.batsmanId === id && bowl.runs > 0
              )
              .map((bowl: { runs: any }) => bowl.runs)
              .reduce((totalRuns: any, runs: any) => totalRuns + runs, 0);

            return {
              id: player._id,
              name: player.name,
              runs: playerRuns,
            };
          }
        );

        setCurrentlyBattingPlayersObject(currentlyBattingPlayersObj);

        // -------------------------------------------------------------------------------------------get Currently Batting Player Selection-------------------------------------------------------------

        let newOnStrikeBatsmanId = "";

        const onCreaseBatters = currentlyBattingPlayersObj?.map(
          (player: any) => player.id
        );

        if (!Array.isArray(playerBowls) || playerBowls.length === 0) {
          newOnStrikeBatsmanId = onCreaseBatters[0];

          setOnStrikeBatsmanId(newOnStrikeBatsmanId);
        } else {
          if (playerBowls.length === 1) {
            const firstBowl = playerBowls[0];

            if (firstBowl.batsmanId === onCreaseBatters[0]) {
              if (firstBowl.status === "RUNS") {
                if (firstBowl.runs % 2 === 0 && firstBowl.runs === 0) {
                  newOnStrikeBatsmanId = onCreaseBatters[0];
                } else if (firstBowl.runs % 2 !== 0) {
                  newOnStrikeBatsmanId = onCreaseBatters[1];
                }
              } else if (firstBowl.status === "NO_BALL") {
                if (
                  (firstBowl.runs - 1) % 2 === 0 ||
                  firstBowl.runs - 1 === 0
                ) {
                  newOnStrikeBatsmanId = onCreaseBatters[0];
                } else if ((firstBowl.runs - 1) % 2 !== 0) {
                  newOnStrikeBatsmanId = onCreaseBatters[1];
                }
              } else if (firstBowl.status === "LEG_BYE") {
                if (firstBowl.runs % 2 === 0 && firstBowl.runs === 0) {
                  newOnStrikeBatsmanId = onCreaseBatters[0];
                } else if (firstBowl.runs % 2 !== 0) {
                  newOnStrikeBatsmanId = onCreaseBatters[1];
                }
              } else if (firstBowl.status === "WIDE") {
                newOnStrikeBatsmanId = onCreaseBatters[0];
              } else if (firstBowl.status === "WICKET") {
                if (firstBowl.runs % 2 === 0 && firstBowl.runs === 0) {
                  newOnStrikeBatsmanId = onCreaseBatters[0]; // ============================================ How do we determine the next batter ?=======================
                } else if (firstBowl.runs % 2 !== 0) {
                  newOnStrikeBatsmanId = onCreaseBatters[1];
                }
              }
            }
          } else if (playerBowls.length > 1) {
            const lastBowl = playerBowls[playerBowls.length - 1];

            const lastBallBatterIndex = onCreaseBatters.indexOf(
              lastBowl.batsmanId
            );

            if (lastBowl.status === "RUNS" || lastBowl.status === "LEG_BYE") {
              if (lastBowl.runs % 2 !== 0) {
                if (lastBallBatterIndex === 0) {
                  newOnStrikeBatsmanId = onCreaseBatters[1];
                } else if (lastBallBatterIndex === 1) {
                  newOnStrikeBatsmanId = onCreaseBatters[0];
                }
              } else if (lastBowl.runs % 2 === 0 || lastBowl.runs === 0) {
                if (lastBallBatterIndex === 0) {
                  newOnStrikeBatsmanId = onCreaseBatters[0];
                } else if (lastBallBatterIndex === 1) {
                  newOnStrikeBatsmanId = onCreaseBatters[1];
                }
              }
            } else if (lastBowl.status === "NO_BALL") {
              if ((lastBowl.runs - 1) % 2 !== 0) {
                if (lastBallBatterIndex === 0) {
                  newOnStrikeBatsmanId = onCreaseBatters[1];
                } else if (lastBallBatterIndex === 1) {
                  newOnStrikeBatsmanId = onCreaseBatters[0];
                }
              } else if (
                (lastBowl.runs - 1) % 2 === 0 ||
                lastBowl.runs - 1 === 0
              ) {
                if (lastBallBatterIndex === 0) {
                  newOnStrikeBatsmanId = onCreaseBatters[0];
                } else if (lastBallBatterIndex === 1) {
                  newOnStrikeBatsmanId = onCreaseBatters[1];
                }
              }
            } else if (lastBowl.status === "WIDE") {
              if (lastBallBatterIndex === 0) {
                newOnStrikeBatsmanId = onCreaseBatters[0];
              } else if (lastBallBatterIndex === 1) {
                newOnStrikeBatsmanId = onCreaseBatters[1];
              }
            } else if (lastBowl.status === "WICKET") {
              if (lastBowl.runs % 2 !== 0) {
                if (lastBallBatterIndex === 0) {
                  newOnStrikeBatsmanId = onCreaseBatters[1];
                } else if (lastBallBatterIndex === 1) {
                  newOnStrikeBatsmanId = onCreaseBatters[0];
                }
              } else if (lastBowl.runs % 2 === 0 || lastBowl.runs === 0) {
                if (lastBallBatterIndex === 0) {
                } else if (lastBallBatterIndex === 1) {
                  newOnStrikeBatsmanId = onCreaseBatters[0];
                }
              }
            }
          }
          setOnStrikeBatsmanId(newOnStrikeBatsmanId);
        }

        // -------------------------------------------------------------------------------------------get all played batters-----------------------------------------------------
        const calculateRunsScored = (
          batsmanId: any,
          playerBowls: any[]
        ): number => {
          return playerBowls.reduce((totalRuns: number, bowl: any) => {
            if (bowl.batsmanId === batsmanId) {
              if (bowl.status === "RUNS") {
                return totalRuns + bowl.runs;
              } else if (bowl.status === "NO_BALL") {
                return totalRuns + (bowl.runs - 1);
              } else if (
                bowl.status === "WICKET" &&
                bowl.runs !== 0 &&
                bowl.status !== "WIDE" &&
                bowl.status !== "LEG_BYE"
              ) {
                return totalRuns + bowl.runs;
              }
            }
            return totalRuns;
          }, 0);
        };

        let allBatters: any[] = [];

        currentlyBattingPlayersObj.forEach((player: any) => {
          const batsmanId = player.id;
          const isOut = playerBowls.some(
            (bowl: any) =>
              bowl.batsmanId === batsmanId && bowl.status === "WICKET"
          );

          const runsScored = calculateRunsScored(batsmanId, playerBowls);

          allBatters.push({
            id: batsmanId,
            name: player.name,
            runsScored: runsScored,
            isOut: isOut,
          });
        });

        playerBowls.forEach((bowl: any) => {
          if (
            bowl.status === "WICKET" &&
            !currentlyBattingPlayersObj.some(
              (player: any) => player.id === bowl.batsmanId
            )
          ) {
            const batsman = findPlayerById(bowl.batsmanId);
            const scoreAtOut =
              calculateRunsScored(bowl.batsmanId, playerBowls) + bowl.runs;
            allBatters.push({
              id: batsman._id,
              name: batsman.name,
              runsScored: scoreAtOut,
              isOut: true,
            });
          }
        });

        allBatters.sort((a, b) => b.runsScored - a.runsScored);

        setAllBattersDetails(allBatters);

        // -------------------------------------------------------------------------------------------get all played bowlers-----------------------------------------------------
        const findBowlerNameById = (bowlerId: any) => {
          const allPlayers = currentBowlingTeamDetails.players; //need an object with the team of currently bowling
          const bowler = allPlayers.find(
            (player: any) => player._id === bowlerId
          );
          return bowler ? bowler.name : "Unknown Bowler";
        };

        const calculateBowlerPerformance = (
          bowlerId: any,
          playerBowls: any[]
        ) => {
          let totalRunsGiven = 0;
          let wicketsTaken = 0;
          playerBowls.forEach((bowl: any) => {
            if (bowl.bowlerId === bowlerId) {
              if (
                bowl.status === "RUNS" ||
                bowl.status === "NO_BALL" ||
                bowl.status === "WIDE" ||
                bowl.status === "LEG_BYE"
              ) {
                totalRunsGiven += bowl.runs;
              } else if (bowl.status === "WICKET") {
                wicketsTaken++;
              }
            }
          });
          return { totalRunsGiven, wicketsTaken };
        };
        const allBowlers: any[] = [];
        const bowlerIds = new Set(
          playerBowls?.map((bowl: any) => bowl.bowlerId)
        );
        bowlerIds.forEach((bowlerId: any) => {
          const bowlerName = findBowlerNameById(bowlerId);
          const { totalRunsGiven, wicketsTaken } = calculateBowlerPerformance(
            bowlerId,
            playerBowls
          );
          allBowlers.push({
            bowlerId,
            bowlerName,
            totalRunsGiven,
            wicketsTaken,
          });
        });

        setAllBowlers(allBowlers);

        // -------------------------------------------------------------------------------------------GET SCOREBOARD ITEMS-----------------------------------------------------
        // -------------------------------------------------get total runs-----------------------------------------------------
        const totalRuns = playerBowls
          .filter(
            (bowl: any) =>
              bowl.status === "RUNS" ||
              bowl.status === "WIDE" ||
              bowl.status === "NO_BALL" ||
              bowl.status === "LEG_BYE" ||
              bowl.status === "WICKET"
          )
          .reduce((total: number, bowl: any) => total + bowl.runs, 0);

        SetTotalRuns(totalRuns);

        // -------------------------------------------------get count of (wide, no ball, leg-bye, wicket)-----------------------------------------------------

        const countNoBalls = playerBowls.filter(
          (bowl: { status: string }) => bowl.status === "NO_BALL"
        ).length;
        const countWickets = playerBowls.filter(
          (bowl: { status: string }) => bowl.status === "WICKET"
        ).length;
        const countWides = playerBowls.filter(
          (bowl: { status: string }) => bowl.status === "WIDE"
        ).length;

        const runsLegByes = playerBowls
          .filter((bowl: any) => bowl.status === "LEG_BYE")
          .reduce((total: number, bowl: any) => total + bowl.runs, 0);

        const newExtrasObject = {
          noBalls: countNoBalls,
          wickets: countWickets,
          wides: countWides,
          legByes: runsLegByes,
        };
        setExtrasCountsObject(newExtrasObject);

        // --------------------------------------------------------------------------OVERS-----------------------------------------------------------

        const calculateBatterStats = (
          playerBowls: Ball[]
        ): Record<string, { ballsFaced: number; oversFaced: number }> => {
          const batterStats: Record<
            string,
            { ballsFaced: number; oversFaced: number }
          > = {};

          playerBowls.forEach((bowl) => {
            if (!batterStats[bowl.batsmanId]) {
              batterStats[bowl.batsmanId] = {
                ballsFaced: 0,
                oversFaced: 0,
              };
            }

            if (
              bowl.status === "RUNS" ||
              bowl.batsmanId === "LEG_BYE" ||
              bowl.batsmanId === "WICKET"
            ) {
              batterStats[bowl.batsmanId].ballsFaced++;
            }

            if (bowl.batsmanId === "RUNS") {
              batterStats[bowl.batsmanId].oversFaced += Math.floor(
                batterStats[bowl.batsmanId].ballsFaced / 6
              );
              batterStats[bowl.batsmanId].ballsFaced %= 6;
            }
          });

          return batterStats;
        };

        const calculateBowlerStats = (
          playerBowls: Ball[]
        ): Record<string, { ballsBowled: number; oversBowled: number }> => {
          const bowlerStats: Record<
            string,
            { ballsBowled: number; oversBowled: number }
          > = {};

          playerBowls.forEach((bowl) => {
            if (!bowlerStats[bowl.bowlerId]) {
              bowlerStats[bowl.bowlerId] = {
                ballsBowled: 0,
                oversBowled: 0,
              };
            }

            if (
              bowl.status === "RUNS" ||
              bowl.status === "LEG_BYE" ||
              bowl.status === "WICKET"
            ) {
              bowlerStats[bowl.bowlerId].ballsBowled++;
            }

            if (bowl.status === "RUNS") {
              bowlerStats[bowl.bowlerId].oversBowled += Math.floor(
                bowlerStats[bowl.bowlerId].ballsBowled / 6
              );
              bowlerStats[bowl.bowlerId].ballsBowled %= 6;
            }
          });

          return bowlerStats;
        };

        const batterOversObjectFirst = calculateBatterStats(
          response.batFirstScoreboard.playerBowls
        );
        const batterOversObjectSecond = calculateBatterStats(
          response.batSecondScoreboard.playerBowls
        );

        const bowlerOversObjectFirst = calculateBowlerStats(
          response.batFirstScoreboard.playerBowls
        );
        const bowlerOversObjectSecond = calculateBowlerStats(
          response.batSecondScoreboard.playerBowls
        );

        const calculateOversFacedByTeam = (playerBowls: Ball[]): number => {
          const ballsFaced = playerBowls.reduce(
            (totalBalls: number, bowl: Ball) => {
              if (
                bowl.status === "RUNS" ||
                bowl.status === "LEG_BYE" ||
                bowl.status === "WICKET"
              ) {
                return totalBalls + 1;
              }
              return totalBalls;
            },
            0
          );

          const oversFaced = Math.floor(ballsFaced / 6) + (ballsFaced % 6) / 10;
          return oversFaced;
        };
        const oversFacedByFirstTeam = calculateOversFacedByTeam(
          response.batFirstScoreboard.playerBowls
        );
        const oversFacedBySecondTeam = calculateOversFacedByTeam(
          response.batSecondScoreboard.playerBowls
        );

        const oversData = {
          firstInningBattersFaced: batterOversObjectFirst,
          secondInningBattersFaced: batterOversObjectSecond,
          firstInningBowlersBowled: bowlerOversObjectFirst,
          secondInningBowlersBowled: bowlerOversObjectSecond,
          totalOversBowledFirst: oversFacedBySecondTeam,
          totalOversBowledSecond: oversFacedByFirstTeam,
        };

        if (isMounted) {
          setOversDataObject(oversData);
        }

        // ------------------------------------------------------------------------------------------first innnings completed-----------------------------------------------------
        function getBatsmenDetailsFromScoreboard(
          scoreboardData: { playerBowls: any[] },
          teamsData: any[]
        ) {
          const batsmenDetails: { id: any; name: any; runs: any }[] = [];

          scoreboardData.playerBowls.forEach(
            (ball: { batsmanId: any; runs: any; status: any }) => {
              const batsmanId = ball.batsmanId;
              const batsmanTeamId = teamsData.find((team: { players: any[] }) =>
                team.players.some(
                  (player: { _id: any }) => player._id === batsmanId
                )
              )?._id;
              const batsmanName = teamsData
                .find((team: { _id: any }) => team._id === batsmanTeamId)
                .players.find(
                  (player: { _id: any }) => player._id === batsmanId
                )?.name;

              let runsScored = 0;

              if (ball.status === "RUNS") {
                runsScored = ball.runs;
              } else if (ball.status === "NO_BALL") {
                runsScored = ball.runs - 1;
              }

              if (runsScored > 0) {
                const existingBatsman = batsmenDetails.find(
                  (batsman) => batsman.id === batsmanId
                );
                if (existingBatsman) {
                  existingBatsman.runs += runsScored;
                } else {
                  batsmenDetails.push({
                    id: batsmanId,
                    name: batsmanName,
                    runs: runsScored,
                  });
                }
              }
            }
          );

          return batsmenDetails;
        }

        const firstInningBatterId =
          response.batFirstScoreboard.currentlyBatting[0];
        const firstInningBattingTeam = response.teams.find(
          (team: { players: any[] }) =>
            team.players.some(
              (player: { _id: any }) => player._id === firstInningBatterId
            )
        );

        const firstInningBatsmenDetails = getBatsmenDetailsFromScoreboard(
          response.batFirstScoreboard,
          [firstInningBattingTeam]
        );

        setFirstInningBatsmenData(firstInningBatsmenDetails);

        function getFirstInningsSummary(scoreboardData: {
          playerBowls: any[];
        }) {
          let totalRuns = 0;
          let wickets = 0;
          let noBalls = 0;
          let wides = 0;
          let legByes = 0;

          scoreboardData.playerBowls.forEach(
            (ball: { status: string; runs: number }) => {
              totalRuns += ball.runs;
              if (ball.status === "NO_BALL") {
                noBalls++;
              } else if (ball.status === "WIDE" || ball.status === "LEG_BYE") {
                if (ball.status === "WIDE") wides++;
                if (ball.status === "LEG_BYE") legByes++;
              }

              if (ball.status === "WICKET") {
                wickets++;
              }
            }
          );

          const firstInningsSummary = {
            totalRuns: totalRuns,
            wickets: wickets,
            noBalls: noBalls,
            wides: wides,
            legByes: legByes,
          };
          return firstInningsSummary;
        }

        const firstInningsSummary = getFirstInningsSummary(
          response.batFirstScoreboard
        );

        setFirstInningsSummary(firstInningsSummary);

        function getFirstInningsBowlerStats(
          scoreboardData: { playerBowls: any[] },
          teamsData: any[]
        ) {
          const bowlersDetails: any = {};

          scoreboardData.playerBowls.forEach(
            (ball: { bowlerId: any; runs: any; status: string }) => {
              const bowlerId = ball.bowlerId;
              const runsGiven = ball.runs;
              const wicketTaken = ball.status === "WICKET" ? 1 : 0;

              const bowlerTeam = teamsData.find((team: { players: any[] }) =>
                team.players.some(
                  (player: { _id: any }) => player._id === bowlerId
                )
              );

              if (bowlerTeam) {
                const bowlerName = bowlerTeam.players.find(
                  (player: { _id: any }) => player._id === bowlerId
                )?.name;

                if (bowlersDetails[bowlerId]) {
                  bowlersDetails[bowlerId].runsGiven += runsGiven;
                  bowlersDetails[bowlerId].wickets += wicketTaken;
                } else {
                  bowlersDetails[bowlerId] = {
                    id: bowlerId,
                    name: bowlerName,
                    runsGiven: runsGiven,
                    wickets: wicketTaken,
                  };
                }
              }
            }
          );

          return Object.values(bowlersDetails);
        }

        const firstInningBowlerStats = getFirstInningsBowlerStats(
          response.batFirstScoreboard,
          response.teams
        );

        setFirstInningBowlerData(firstInningBowlerStats);

        const tournamentTitle = response.tournamentId.name;

        setTournamentTitle(tournamentTitle);
      })

      .catch((err: any) => {
        console.log("err", err);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center dashboard-header">
        <span className="f-25 f-color-blue font-inter ">{tournamentTitle}</span>
      </div>
      <ScoreCard setPageNumber={setPageNumber} />

      {pageNumber === 1 && (
        <>
          <BallScoreCardComponent
            fetchTournament={fetchMatch}
            battingFirstTeam={currentlyBattingTeamDetails?.name}
            ballingFirstTeam={currentlyBowlingteamObject?.name}
            match={matchData}
            battingFirst={pageNumber == 1 ? false : true}
          ></BallScoreCardComponent>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="container-fluid mt-3 pl-pr-0">
                  <div className="d-flex h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
                    <div className="container">
                      <div className="row border-top-10 bg-gray2 d-flex justify-content-center align-items-center">
                        <span className="text-black line-h-24 f-20 mt-12 mb-12 font-inter">
                          <img src={GreenDot} className="pr-3" />
                          Bowling Team : {currentlyBowlingteamObject?.name}
                        </span>
                      </div>
                      <div className="row mt-4 mb-4 pl-5 pr-5">
                        <div className="between-content">
                          <div>
                            <span className="fw-400 f-20 f-color-gray font-inter">
                              Current Bowler
                            </span>
                          </div>
                          <div>
                            <span className="fw-400 f-20 f-color-gray font-inter">
                              Runs/Wickets
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row  pl-5 pr-5">
                        <div className="between-content ">
                          <div className="">
                            <span className=" fw-400 f-20 f-color-black font-inter">
                              {currentBowlerData?.name}
                            </span>
                          </div>
                          <div>
                            <span className="fw-400 f-20 f-color-black font-inter">
                              {currentBowlerStats?.runsGiven}/
                              {currentBowlerStats?.wicketsTaken}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-5 border-top-10 bg-gray2 d-flex justify-content-center align-items-center">
                        <span className=" text-black line-h-24 f-20 mt-12 mb-12 font-inter">
                          <img src={BatIcon} className="pr-3" />
                          Batting Team : {currentlyBattingTeamDetails?.name}
                        </span>
                      </div>
                      <div className="row mt-4 mb-4 pl-5 pr-5">
                        <div className="between-content">
                          <div>
                            <span className="fw-400 f-20 f-color-gray font-inter">
                              On Strike
                            </span>
                          </div>
                          <div>
                            <span className="fw-400 f-20 f-color-gray font-inter">
                              Runs
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row  pl-5 pr-5">
                        <div className="between-content">
                          <div className="border-g-10">
                            <span className=" fw-400 f-20 f-color-black font-inter">
                              <div className="border-g-10">
                                {currentlyBattingPlayersObject?.map(
                                  (player: any) => (
                                    <p
                                      key={player.id}
                                      className="fw-400 f-20 f-color-black font-inter"
                                    >
                                      {/* {player.id === onStrikeBatsmanId
                                        ? player.name + "*"
                                        : player.name} */}
                                      {player.name}
                                    </p>
                                  )
                                )}
                              </div>
                            </span>
                          </div>
                          <div>
                            {currentlyBattingPlayersObject?.map(
                              (player: any) => (
                                <p
                                  key={`batting-${player.id}`}
                                  className="fw-400 f-20 f-color-black font-inter"
                                >
                                  {player.runs}
                                </p>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="container-fluid mt-3 pl-pr-0">
                  <div className="d-flex w-100 h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
                    <div className="row w-100">
                      <div className="col-md-6">
                        <div className="container">
                          <div className="row border-top-10 bg-gray2 d-flex justify-content-center align-items-center">
                            <span className="text-black line-h-24 f-20 mt-12 mb-12 font-inter">
                              Scoreboard
                            </span>
                          </div>
                          {allBattersDetails?.map(
                            (
                              batsman: {
                                id: React.Key | null | undefined;
                                name:
                                  | boolean
                                  | React.ReactChild
                                  | React.ReactFragment
                                  | React.ReactPortal
                                  | null
                                  | undefined;
                                runsScored:
                                  | boolean
                                  | React.ReactChild
                                  | React.ReactFragment
                                  | React.ReactPortal
                                  | null
                                  | undefined;
                              },
                              index: number
                            ) => (
                              <div
                                className={`row ${
                                  index % 2 !== 0 ? "bg-gray3" : ""
                                }`}
                                key={batsman.id}
                              >
                                <div className="between-content pl-1 pr-1 pt-2 pb-2">
                                  <div>
                                    <span className="fw-400 f-15 f-color-gray font-inter">
                                      {batsman.name}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="fw-400 f-15 f-color-gray font-inter">
                                      {batsman.runsScored}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )
                          )}

                          <div className="row   bg-blue2">
                            <div className="between-content pl-1 pr-1 pt-2 pb-2">
                              <div className="border-g-10">
                                <span className=" fw-500 f-15 f-color-black font-inter">
                                  Total
                                </span>
                              </div>
                              <div>
                                <span className="fw-500 f-15 f-color-black font-inter">
                                  {totalRuns}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row d-flex justify-content-center align-items-center pl-1 pr-1 bg-gray4">
                            <div className="between-content pl-1 pr-1">
                              <div className="bg-gray4 h-1 pl-1 pr-1"></div>
                            </div>
                          </div>
                          <div className="row border-bottom-10 d-flex justify-content-center align-items-center  bg-blue2">
                            <div>
                              <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                Wicket:
                              </span>
                              <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                {extrasObject.wickets}
                              </span>
                              <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                No ball:
                              </span>
                              <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                {extrasObject.noBalls}
                              </span>
                            </div>
                            <div>
                              <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                Wide:
                              </span>
                              <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                {extrasObject.wides}
                              </span>
                              <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                Leg Bye:
                              </span>
                              <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                {extrasObject.legByes}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="container">
                          <div className="row border-top-10 bg-gray2 d-flex justify-content-center align-items-center">
                            <span className="text-black line-h-24 f-20 mt-12 mb-12 font-inter">
                              Bowling Stats
                            </span>
                          </div>
                          {allBowlers?.map((bowler, index) => (
                            <div
                              className={`row ${
                                index % 2 !== 0 ? "bg-gray3" : ""
                              }`}
                              key={bowler.bowlerId}
                            >
                              <div className="between-content pl-1 pr-1 pt-2 pb-2">
                                <div>
                                  <span className="fw-400 f-15 f-color-gray font-inter">
                                    {bowler.bowlerName}
                                  </span>
                                </div>
                                <div>
                                  <span className="fw-400 f-15 f-color-gray font-inter">
                                    {bowler.totalRunsGiven} /{" "}
                                    {bowler.wicketsTaken}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {pageNumber === 2 && (
        <>
          <BallScoreCardComponent
            fetchTournament={fetchMatch}
            battingFirstTeam={currentlyBattingTeamDetails?.name}
            ballingFirstTeam={currentlyBowlingteamObject?.name}
            match={matchData}
            battingFirst={pageNumber == 2 ? true : false}
          ></BallScoreCardComponent>
          <div className="container">
            <div className="container-fluid mt-3 pl-pr-0">
              <div className="d-flex w-100 h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
                <div className="row w-100">
                  <div className="col-md-6">
                    <div className="container">
                      <div className="row border-top-10 bg-gray2 d-flex justify-content-center align-items-center">
                        <span className="text-black line-h-24 f-20 mt-12 mb-12 font-inter">
                          Scoreboard First Innings
                        </span>
                      </div>
                      {firstInningBatsmenData?.map(
                        (
                          batsman: { id: any; name: any; runs: any },
                          index: number
                        ) => (
                          <div
                            className={`row ${
                              index % 2 !== 0 ? "bg-gray3" : ""
                            }`}
                            key={batsman.id}
                          >
                            <div className="between-content pl-1 pr-1 pt-2 pb-2">
                              <div>
                                <span className="fw-400 f-15 f-color-gray font-inter">
                                  {batsman.name}
                                </span>
                              </div>
                              <div>
                                <span className="fw-400 f-15 f-color-gray font-inter">
                                  {batsman.runs}
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      )}

                      <div className="row   bg-blue2">
                        <div className="between-content pl-1 pr-1 pt-2 pb-2">
                          <div className="border-g-10">
                            <span className=" fw-500 f-15 f-color-black font-inter">
                              Total
                            </span>
                          </div>
                          <div>
                            <span className="fw-500 f-15 f-color-black font-inter">
                              {firstInningsSummary?.totalRuns}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center align-items-center pl-1 pr-1 bg-gray4">
                        <div className="between-content pl-1 pr-1">
                          <div className="bg-gray4 h-1 pl-1 pr-1"></div>
                        </div>
                      </div>
                      <div className="row border-bottom-10 d-flex justify-content-center align-items-center  bg-blue2">
                        <div>
                          <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                            Wicket:
                          </span>
                          <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                            {firstInningsSummary?.wickets}
                          </span>
                          <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                            No ball:
                          </span>
                          <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                            {firstInningsSummary?.noBalls}
                          </span>
                        </div>
                        <div>
                          <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                            Wide:
                          </span>
                          <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                            {firstInningsSummary?.wides}
                          </span>
                          <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                            Leg Bye:
                          </span>
                          <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                            {firstInningsSummary?.legByes}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="container">
                      <div className="row border-top-10 bg-gray2 d-flex justify-content-center align-items-center">
                        <span className="text-black line-h-24 f-20 mt-12 mb-12 font-inter">
                          Bowling Stat First Innings
                        </span>
                      </div>
                      {firstInningBowlerData?.map(
                        (
                          bowler: {
                            id: React.Key | null | undefined;
                            name:
                              | boolean
                              | React.ReactChild
                              | React.ReactFragment
                              | React.ReactPortal
                              | null
                              | undefined;
                            runsGiven:
                              | string
                              | number
                              | boolean
                              | {}
                              | React.ReactElement<
                                  any,
                                  string | React.JSXElementConstructor<any>
                                >
                              | React.ReactNodeArray
                              | React.ReactPortal
                              | null
                              | undefined;
                            wickets:
                              | string
                              | number
                              | boolean
                              | {}
                              | React.ReactElement<
                                  any,
                                  string | React.JSXElementConstructor<any>
                                >
                              | React.ReactNodeArray
                              | React.ReactPortal
                              | null
                              | undefined;
                          },
                          index: number
                        ) => (
                          <div
                            className={`row ${
                              index % 2 !== 0 ? "bg-gray3" : ""
                            }`}
                            key={bowler.id}
                          >
                            <div className="between-content pl-1 pr-1 pt-2 pb-2">
                              <div>
                                <span className="fw-400 f-15 f-color-gray font-inter">
                                  {bowler.name}
                                </span>
                              </div>
                              <div>
                                <span className="fw-400 f-15 f-color-gray font-inter">
                                  {bowler?.runsGiven} / {bowler?.wickets}
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {pageNumber === 3 && (
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="container-fluid mt-3 pl-pr-0">
                <div className="d-flex flex-column w-100 h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
                  <div className="container mt-3">
                    <div className="row border-top-10 bg-gray2 d-flex justify-content-center align-items-center">
                      <span className="text-black line-h-24 f-20 mt-12 mb-12 font-inter">
                        Batting First Team Scoreboard
                      </span>
                    </div>
                    {firstInningBatsmenData?.map(
                      (
                        batsman: { id: any; name: any; runs: any },
                        index: number
                      ) => (
                        <div
                          className={`row ${index % 2 !== 0 ? "bg-gray3" : ""}`}
                          key={batsman.id}
                        >
                          <div className="between-content pl-1 pr-1 pt-2 pb-2">
                            <div>
                              <span className="fw-400 f-15 f-color-gray font-inter">
                                {batsman.name}
                              </span>
                            </div>
                            <div>
                              <span className="fw-400 f-15 f-color-gray font-inter">
                                {batsman.runs}
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    )}

                    <div className="row   bg-blue2">
                      <div className="between-content pl-1 pr-1 pt-2 pb-2">
                        <div className="border-g-10">
                          <span className=" fw-500 f-15 f-color-black font-inter">
                            Total
                          </span>
                        </div>
                        <div>
                          <span className="fw-500 f-15 f-color-black font-inter">
                            {firstInningsSummary?.totalRuns}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center pl-1 pr-1 bg-gray4">
                      <div className="between-content pl-1 pr-1">
                        <div className="bg-gray4 h-1 pl-1 pr-1"></div>
                      </div>
                    </div>
                    <div className="row border-bottom-10 d-flex justify-content-center align-items-center  bg-blue2">
                      <div>
                        <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                          Wicket:
                        </span>
                        <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                          {firstInningsSummary?.wickets}
                        </span>
                        <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                          No ball:
                        </span>
                        <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                          {firstInningsSummary?.noBalls}
                        </span>
                      </div>
                      <div>
                        <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                          Wide:
                        </span>
                        <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                          {firstInningsSummary?.wides}
                        </span>
                        <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                          Leg Bye:
                        </span>
                        <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                          {firstInningsSummary?.legByes}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="container mt-3">
                    <div className="row border-top-10 bg-gray2 d-flex justify-content-center align-items-center">
                      <span className="text-black line-h-24 f-20 mt-12 mb-12 font-inter">
                        Batting First Team Bowling Stat
                      </span>
                    </div>
                    {firstInningBowlerData?.map(
                      (bowler: any, index: number) => (
                        <div
                          className={`row ${index % 2 !== 0 ? "bg-gray3" : ""}`}
                          key={bowler.id}
                        >
                          <div className="between-content pl-1 pr-1 pt-2 pb-2">
                            <div>
                              <span className="fw-400 f-15 f-color-gray font-inter">
                                {bowler?.name}
                              </span>
                            </div>
                            <div>
                              <span className="fw-400 f-15 f-color-gray font-inter">
                                {bowler?.runsGiven} / {bowler?.wickets}
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="container-fluid mt-3 pl-pr-0">
                <div className="d-flex flex-column w-100 h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
                  <div className="container mt-3">
                    <div className="row border-top-10 bg-gray2 d-flex justify-content-center align-items-center">
                      <span className="text-black line-h-24 f-20 mt-12 mb-12 font-inter">
                        Batting Second Team Scoreboard
                      </span>
                    </div>
                    {allBattersDetails?.map(
                      (
                        batsman: {
                          id: React.Key | null | undefined;
                          name:
                            | boolean
                            | React.ReactChild
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined;
                          runsScored:
                            | boolean
                            | React.ReactChild
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined;
                        },
                        index: number
                      ) => (
                        <div
                          className={`row ${index % 2 !== 0 ? "bg-gray3" : ""}`}
                          key={batsman.id}
                        >
                          <div className="between-content pl-1 pr-1 pt-2 pb-2">
                            <div>
                              <span className="fw-400 f-15 f-color-gray font-inter">
                                {batsman.name}
                              </span>
                            </div>
                            <div>
                              <span className="fw-400 f-15 f-color-gray font-inter">
                                {batsman.runsScored}
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    )}

                    <div className="row   bg-blue2">
                      <div className="between-content pl-1 pr-1 pt-2 pb-2">
                        <div className="border-g-10">
                          <span className=" fw-500 f-15 f-color-black font-inter">
                            Total
                          </span>
                        </div>
                        <div>
                          <span className="fw-500 f-15 f-color-black font-inter">
                            {totalRuns}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center pl-1 pr-1 bg-gray4">
                      <div className="between-content pl-1 pr-1">
                        <div className="bg-gray4 h-1 pl-1 pr-1"></div>
                      </div>
                    </div>
                    <div className="row border-bottom-10 d-flex justify-content-center align-items-center  bg-blue2">
                      <div>
                        <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                          Wicket:
                        </span>
                        <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                          {extrasObject?.wickets}
                        </span>
                        <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                          No ball:
                        </span>
                        <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                          {extrasObject?.noBalls}
                        </span>
                      </div>
                      <div>
                        <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                          Wide:
                        </span>
                        <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                          {extrasObject?.wides}
                        </span>
                        <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                          Leg Bye:
                        </span>
                        <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                          {extrasObject?.legByes}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="container mt-3">
                    <div className="row border-top-10 bg-gray2 d-flex justify-content-center align-items-center">
                      <span className="text-black line-h-24 f-20 mt-12 mb-12 font-inter">
                        Batting Second Team Bowling Stat
                      </span>
                    </div>
                    {allBowlers?.map((bowler, index) => (
                      <div
                        className={`row ${index % 2 !== 0 ? "bg-gray3" : ""}`}
                        key={bowler?.bowlerId}
                      >
                        <div className="between-content pl-1 pr-1 pt-2 pb-2">
                          <div>
                            <span className="fw-400 f-15 f-color-gray font-inter">
                              {bowler?.bowlerName}
                            </span>
                          </div>
                          <div>
                            <span className="fw-400 f-15 f-color-gray font-inter">
                              {bowler?.totalRunsGiven} / {bowler?.wicketsTaken}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LiveScore;
