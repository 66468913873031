import axios from "axios";

import { TeamData } from "../models/Team";
import { TournamentData } from "../models/Tournament";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";

export class AdminService {

  public static async getTeamsByTournamentId(tournament:any): Promise<AppResponse<TeamData[]>> {
    const url = Util.apiAuthUrl(`teams/${tournament}`);
    return await axios.get<Partial<TeamData[]>, AppResponse<TeamData[]>>(url);
  }
  public static async createTournament(data: Partial<TournamentData>): Promise<AppResponse<TournamentData>> {
    const url = Util.apiAuthUrl("tournament");
    return await axios.post<Partial<TournamentData>, AppResponse<TournamentData>>(url, data);
  }
  public static async getMatchesByTournamentId(tournament:any): Promise<AppResponse<any[]>> {
  
    const url = Util.apiAuthUrl("matches-by-tournament/"+tournament);
    console.log("url",url)
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }
  public static async getTournamentDataByTournamentId(tournament:any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`tournament/${tournament}`);
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }
  public static async createTeam(data: Partial<TeamData>): Promise<AppResponse<TeamData>> {
    const url = Util.apiAuthUrl("teams/create");
    return await axios.post<Partial<TeamData>, AppResponse<TeamData>>(url, data);
  }
  public static async createMatch(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("match/create");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  public static async updateMatchTeams(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("update-teams");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  public static async getTournamentList(): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl("tournaments");
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }
  public static async getAllMatchesData(tournament:any): Promise<AppResponse<any[]>> {
  
    const url = Util.apiAuthUrl("matches-by-tournament/"+tournament);
    console.log("url",url)
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }
  
  public static async getAllTeamsByTournamentId(tournament:any): Promise<AppResponse<any[]>> {
  
    const url = Util.apiAuthUrl("teams/"+tournament);
    console.log("url",url)
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }
  public static async updateMatchTossWinTeams(data: Partial<any>): Promise<AppResponse<any>> {
    console.log("data",data)
    const url = Util.apiAuthUrl("update-macth-status");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  public static async updateMatchCurrentPlayers(data: Partial<any>): Promise<AppResponse<any>> {
    console.log("data",data)
    const url = Util.apiAuthUrl("update-current-players");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  public static async finalizedMatch(data: Partial<any>): Promise<AppResponse<any>> {
    console.log("data",data)
    const url = Util.apiAuthUrl("match/finalize");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
}
