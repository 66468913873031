import React, { useEffect, useState, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import "../vendors/styles/cricketAppStyles.css";
import createTournamentProfile from "../../components/vendors/images/createTournamentProfile.svg";
import search from "../../components/vendors/images/search.svg";
import { AdminService } from "../../services/AdminService";
import TournamentContext from "../../context/TournamentContext";
const TournamentList: React.FC = () => {
  const [tournamentList, setTournamentList] = useState<any[]>();
  const [tournament, setTournament] = useContext(TournamentContext);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredTournaments, setFilteredTournaments] = useState<any[]>();
  const history = useHistory();
  useEffect(() => {
    getTeams();
  }, []);
  useEffect(() => {
    filterTournaments();
  }, [tournamentList, searchTerm]);
  const getTeams = () => {
    AdminService.getTournamentList().then((res) => {
      if (res.success) {
        setTournamentList(res.data);
      } else {
        console.log("error", res.error);
      }
    });
  };

  const handleTournamentClick = (tournament: any) => {
    setTournament(tournament);
    history.push(`/admin/dashboard/${tournament?._id}`);
  };
  

  const filterTournaments = () => {
    const filtered = tournamentList?.filter((tournament) =>
      tournament.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTournaments(filtered);
  };

  return (
    <>
      <div className="login-page">
        <div className="container">
          <div className="login-body d-flex justify-content-center align-items-center">
            <div className="row">
              <div className="col-lg-6">
                <div className="tournament-list-container border-r-10">
                  <div className="d-flex justify-content-center align-items-center mb-4">
                    <span className="mb-1 h-1 text-center tournament-creation-header mb-4">
                      Tournaments
                    </span>
             
                  </div>
                  <div className="d-lg-flex justify-content-center mt-2 mb-5">
                    <NavLink to="/admin/create-tournament">
                      <button className="list-tournament-btn">
                        Create new Tournament
                        <span className="fas fa-chevron-right ml-1"></span>
                      </button>
                    </NavLink>
                  </div>

                  <div className=" d-flex  justify-content-end tournament-list-img-container">
                    <img
                      src={createTournamentProfile}
                      alt="Right Background"
                      className="tournament-list-img"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="tournament-list-container border-r-10 ">
                  <div className="d-lg-flex justify-content-between mb-4">
                    <span className=" h-1  tournament-list-creation-header mb-4 mt-1">
                      Tournament List
                    </span>
                    <div className="d-flex  align-items-center tournament-list-search m-1 mt-2">
                    <img
                      src={search}
                      alt="Search"
                      className="mr-1"
                    />
                    <input
          type="text"
          className="search-input"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
                    </div>
               
                  </div>

                  <div className="table-responsive mt-5 tournament-tabel">
                    <table className="table ">
                      <tbody className="tournament-list-table">
                        {/* {tournamentList?.map((tournament: any, index: any) => (
                          <tr
                            className="d-flex justify-content-between flex-wrap"
                            key={index}
                            onClick={() => handleTournamentClick(tournament)}
                          >
                            <td>{tournament.name}</td>
                          </tr>
                        ))} */}
                         {filteredTournaments?.map((tournament: any, index: any) => (
              <tr
                className="d-flex justify-content-between flex-wrap"
                key={index}
                onClick={() => handleTournamentClick(tournament)}
              >
                <td>{tournament.name}</td>
              </tr>
            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TournamentList;
